export const subject = "{teamName} - {gameDateTime}"

export const body = `
<!DOCTYPE html>
<html lang="en-US">
<head>
    <title>Email Template</title>
</head>
<body>
    <div>
        <p>Hi {playerName},</p>
        <br />
        <p>This is a GameLogin reminder for your upcoming game on {gameDateTime} ({gameLocation}).</p>
        <p>Visit your team page at <a href="https://gamelogin.com/{teamId}">GameLogin.com/{teamId}</a></p>
        <br />
        <br />
        <br />
        <br />
        <p>This is an automated message, please do not reply to it.</p>
        <br />
        <p>Click <a href="https://gamelogin.com/{teamId}/unsubscribe/{playerId}">here</a> to unsubscribe from all future messages from your team.</p>
    </div>
</body>
</html>
`;