import EditIcon from '@mui/icons-material/Edit';
import { Box, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CreateGame from '../Components/CreateGame';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: 900,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    padding: '6px 10px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const UpcomingGames = ({teamData, setTeamData, allowEdit, showUpcomingGame = false}) => {
    const [showEditGameModal, setShowEditGameModal] = useState(false);
    const [editGameId, setEditGameId] = useState(null);

    const formatGameData = (nextGameId) => {
        const gameLoc = nextGameId.split('_')[1]

        const utcDateObj = new Date(nextGameId.split('_')[0]);
        const options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: teamData.timeZone
          };
        const gameDateTime = utcDateObj.toLocaleString('en-US', options);

        return [gameDateTime, gameLoc];
    }

    const handleEditGame = (gameId) => {
        console.log(gameId);
        setEditGameId(gameId);
        setShowEditGameModal(true);
    };

    const generateTableBody = () => {
        const tableBody = [];
        const currentDate = new Date(); ////////
        currentDate.setHours(currentDate.getHours() - 1);
        const updateTime = currentDate.toISOString();
        let upcomingGameFound = false;
        for (let i = 0; i < teamData.games.length; i++) {
            const gameId = teamData.games[i];
            if (teamData.games[i] > updateTime) {
                if (!showUpcomingGame && !upcomingGameFound) {
                    upcomingGameFound = true;
                    continue;
                }
                const [gameDateTime, gameLocation] = formatGameData(gameId);
                tableBody.push(
                    <StyledTableRow key={gameId}>
                        <StyledTableCell component="th" scope="row">
                            {gameDateTime}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                            {gameLocation}
                        </StyledTableCell>
                        {allowEdit ? (
                        <StyledTableCell component="th" scope="row">
                            <EditIcon className='edit-game-button' onClick={() => handleEditGame(gameId)}/>
                        </StyledTableCell>
                        ) : null}
                    </StyledTableRow>
                );
            }
        }

        if (tableBody.length > 0) {
            return tableBody;
        }
        else {
            return (
                <StyledTableRow key={'none'}>
                        <StyledTableCell colSpan={5} component="th" scope="row">
                            {'No Future Games'}
                        </StyledTableCell>
                </StyledTableRow>
            )
        }
    }

    return (
        <div>
            <TableContainer sx={{margin: "10px auto", width: "315px"}} component={Paper}>
                <Table sx={{ width: 314, margin: 0}} size='small' aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" colSpan={3}>
                                <p className='game-table-header'>Future Games</p>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell align="left">
                                <p className='game-table-header'>Date</p>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <p className='game-table-header'>Location</p>
                            </StyledTableCell>
                            {allowEdit ? (
                            <StyledTableCell align="center">
                                <p className='game-table-header'>EDIT</p>
                            </StyledTableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    {teamData.games && teamData.games.length > 0 ? (
                    <TableBody>
                        {generateTableBody()}
                    </TableBody>
                    ) : (
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={3} align='center' scope="row">
                                None
                            </StyledTableCell>
                        </TableRow>
                    </TableBody>
                    )}
                </Table>
            </TableContainer>
            <Modal
                open={showEditGameModal}
                onClose={() => setShowEditGameModal(false)}
                aria-labelledby="autologin-info-title"
                aria-describedby="autologin-info-description"
            >
                <Box sx={boxStyle}>
                    <CreateGame
                        teamData={teamData}
                        setTeamData={setTeamData}
                        editGameId={editGameId}
                        setShowEditGameModal={setShowEditGameModal}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default UpcomingGames;