import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import baseApiUrl from '../Modules/environment-config';
import { refreshCognitoTokens } from './cognito';


const axiosAuth = axios.create({
    baseURL: baseApiUrl,
});

axiosAuth.interceptors.request.use(
    async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
        // Check if tokens need to be refreshed
        const refreshTime = localStorage.getItem('refreshTime'); // Get token expiration time from localStorage
        if (refreshTime) {
            const currentTime = Math.floor(Date.now() / 1000);
            if (currentTime > parseInt(refreshTime)) {
                console.log('refreshing tokens...');
                try {
                    await refreshCognitoTokens();
                } catch (err) {
                    console.error('Token refresh error:', err);
                }
            }
        }
        const token = localStorage.getItem('idToken');
        if (token) {
            if (config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                config.headers = { Authorization: `Bearer ${token}` } as AxiosRequestHeaders;
            }
        }
        return config;
    },
    (error: AxiosError): Promise<AxiosError> => {
        console.log('3');
        return Promise.reject(error);
    }
);

axiosAuth.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    async (error: AxiosError): Promise<AxiosError> => {
        // Commenting out this check for 401 error, still not sure how to catch them. 
        // They don't seem to trigger this code block at all. (outside the if)
        if (error.response && error.response.status === 401) {
            console.log('401 RESPONSE');
            try {
                // Handle token refresh logic
                await refreshCognitoTokens();
                // Retry the failed request
                return axiosAuth(error.config as InternalAxiosRequestConfig);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);




export default axiosAuth;