import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../utils/cognito.ts';


const ManagerLogin = (props) => {
    const teamId = props.teamId ? props.teamId : null;
    const [teamCode, setTeamCode] = useState('');
    const [managerPassword, setManagerPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState(' ');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const teamCodeInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const navigate = useNavigate(); // Create a history object

    useEffect(() => {
        // Check if the component is visible and the input ref is set
        if (teamId) {
            passwordInputRef.current.focus();
        } else {
            teamCodeInputRef.current.focus();
        }
    }, [teamId]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyPress = (event) => {
        // Check if the pressed key is 'Enter'
        if (event.key === 'Enter') {
          handleButtonClick();
        }
    };

    const validateManagerPassword = async () => {
        try{
            await authenticateUser(
                teamId ? teamId : teamCode.toLowerCase().trim(),
                managerPassword
            )

            navigate(
                `/${teamId ? teamId : teamCode.toLowerCase()}/manage`
            );
        } catch (error) {
            if (error.name === 'NotAuthorizedException') {
                setLoginError(true);
                setLoginErrorMessage(`Incorrect team code or password`);
            }
            else if (error.name === 'UserNotFoundException') {
                setLoginError(true);
                setLoginErrorMessage(`Team does not exist`);
            }
            else if (error.name === 'UserNotConfirmedException') {
                console.log('Team requires email confirmation');
                navigate(`${teamId ? teamId : teamCode.toLowerCase().trim()}/create`);
                localStorage.setItem('managerPassword', managerPassword);
            }
            else if (!error.response) {
                setLoginError(true);
                setLoginErrorMessage(`Login failed, please try again later.`);
                console.log(error);
            }
            else if (error.response.status === 401) {
                setLoginError(true);
                setLoginErrorMessage(`Incorrect password`);
            }
            else {
                setLoginError(true);
                setLoginErrorMessage(`Login failed, please try again later.`);
                console.log(error);
            }
            return;
        }

        
    }
    
    const handleButtonClick = async () => {
        if (!teamId && (!teamCode || !managerPassword)) {
            setLoginError(true);
            setLoginErrorMessage('Team code and password required');
            return;
        }
        setIsLoggingIn(true);
        // Verify if the entered password is correct
        // Later this can be replaced with Cognito
        await validateManagerPassword()
        setIsLoggingIn(false);
    };

    return (
        <div className="manager-input-container">
            <h3>Management Access</h3>
            {!teamId ? (
            <TextField
                inputRef={teamCodeInputRef}
                error={loginError}
                id="team-code"
                label="Team Code"
                onChange={(event) => {
                setTeamCode(event.target.value);
                }}
                onKeyDown={handleKeyPress}
                helperText=' '
            />) : null
            }
            <FormControl id="manager-password-input" error={loginError} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    inputRef={passwordInputRef}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                    onChange={(event) => {
                        setManagerPassword(event.target.value)
                    }}
                    onKeyDown={handleKeyPress}
                />
                <FormHelperText>{loginErrorMessage}</FormHelperText>
            </FormControl>
            <div className="manager-go-button">
                <Button variant="contained" onClick={handleButtonClick} disabled={isLoggingIn}>
                    Go
                </Button>
            </div>
          </div>
      );
}

export default ManagerLogin;