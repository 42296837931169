import {
  ConfirmSignUpCommand,
  ConfirmSignUpCommandInput,
  SignUpCommand,
  SignUpCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { client, poolClientId } from '../Modules/cognito-config';
import axiosAuth from '../utils/axiosAuth';
import { authenticateUser } from '../utils/cognito';

interface TeamSignUpProps {
  userConfirmed?: boolean;
  teamId?: string;
}

  const TeamSignUp: React.FC<TeamSignUpProps> = ({userConfirmed = true, teamId}) => {
    const [teamCode, setTeamCode] = useState(teamId ? teamId : '');
    const [managerName, setManagerName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [managerPassword, setManagerPassword] = useState('');
    const [teamName, setTeamName] = useState('');
    const [teamSport, setTeamSport] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [signedUp, setSignedUp] = useState(userConfirmed ? false : true);
    const [signUpError, setSignUpError] = useState(false);
    const teamNameInputRef = useRef(null);
    const navigate = useNavigate(); // Create a history object
    const teamApiUrl = `/teams/`;
  
    const handleSignUp = async () => {
      setError('');
      setSignUpError(false);

      if (!managerEmail || !managerPassword || !teamCode || !teamName) {
        setError('Please enter the required fields');
        setSignUpError(true);
        return;
      }

      if (!passwordValid(managerPassword)) {
        return;
      }
  
      try {
        const signUpParams: SignUpCommandInput = {
          ClientId: poolClientId,
          Username: teamCode,
          Password: managerPassword,
          UserAttributes: [
            {
              Name: 'email',
              Value: managerEmail,
            },
          ],
        };
  
        const command = new SignUpCommand(signUpParams);
        await client.send(command);

         // Create team data in database
         const teamData = {
          id: teamCode,
          managerName: managerName,
          name: teamName,
          sport: teamSport,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const createTeamResp = await axiosAuth.post(teamApiUrl + teamCode, teamData);
        console.log(createTeamResp);

        // Registration successful, request email verification
        setSignedUp(true);
      } catch (err: any) {
        setSignUpError(true);
        setError(err.message || 'An error occurred during registration');
        console.error('Error during registration:', err);
      }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.FormEvent) => {
      event.preventDefault();
    };
  
    const handleVerifyEmail = async () => {
      setError('');
      setSignUpError(false);
      try {
        const confirmParams: ConfirmSignUpCommandInput = {
          Username: teamCode,
          ClientId: poolClientId,
          ConfirmationCode: verificationCode,
        };
  
        const confirmCommand = new ConfirmSignUpCommand(confirmParams);
        await client.send(confirmCommand);
        await authenticateUser(
          teamCode,
          managerPassword ? managerPassword : localStorage.managerPassword
        );
        // Email verification successful
        console.log('Email verification successful');
        // Create team resources and redirect to management page
        navigate(
          `/${teamCode.toLowerCase()}/manage`
        );
      } catch (err: any) {
        setError(err.message || 'An error occurred during email verification');
        setSignUpError(true);
        console.error('Error during email verification:', err);
      }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      handleSignUp();
    }

    const handleKeyPress = (event: { key: string; }) => {
      // Check if the pressed key is 'Enter'
      if (event.key === 'Enter') {
        handleSignUp();
      }
    };

    const passwordValid = (password: string) => {
      const passwordPolicy = {
        minLength: 6,
        requireLowercase: true,
        requireDigits: true,
        requireSymbols: true,
      };
    
      const lowercaseRegex = /[a-z]/;
      const digitRegex = /[0-9]/;
      const symbolRegex = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/;
    
      // Check if password meets minimum length requirement
      if ((password.length < passwordPolicy.minLength) 
          || (passwordPolicy.requireLowercase && !lowercaseRegex.test(password)) 
          || (passwordPolicy.requireDigits && !digitRegex.test(password) )
          || (passwordPolicy.requireSymbols && !symbolRegex.test(password))) {
        setError('Password must be at least 6 characters long, contain one lowercase letter, one digit and one symbol');
        return false;
      }
    
      // Password meets all criteria
      return true;
    };

    return (
      <div style={signUpFieldsStyle}>
        <h2>Create an Account</h2>
        {!signedUp ? (
          <form style={signUpFieldsStyle} onSubmit={handleSubmit}>
            <TextField
                ref={teamNameInputRef}
                error={signUpError}
                id="team-name"
                label="Team Name"
                onChange={(event) => {
                  setTeamName(event.target.value);
                }}
                sx={{width: '230px'}}
                onKeyDown={handleKeyPress}
                helperText=' '
            />
            <TextField
                error={signUpError}
                id="team-code"
                label="Team Code"
                onChange={(event) => {
                  setTeamCode(event.target.value);
                }}
                sx={{width: '230px'}}
                onKeyDown={handleKeyPress}
                helperText=' '
            />
            
            <TextField
                error={signUpError}
                id="manager-email"
                label="Manager Email"
                onChange={(event) => {
                  setManagerEmail(event.target.value);
                }}
                sx={{width: '230px'}}
                onKeyDown={handleKeyPress}
                helperText=' '
            />
            <FormControl id="manager-password-input" error={signUpError} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Manager Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    error={signUpError}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Manager Password"
                    onChange={(event) => {
                        setManagerPassword(event.target.value)
                    }}
                    onKeyDown={handleKeyPress}
                />
                <FormHelperText>{' '}</FormHelperText>
            </FormControl>
            <TextField
                id="manager-name"
                label="Manager Name"
                onChange={(event) => {
                  setManagerName(event.target.value);
                }}
                sx={{width: '230px'}}
                onKeyDown={handleKeyPress}
                helperText=' '
            />
            <TextField
                id="team-sport"
                label="Sport"
                onChange={(event) => {
                  setTeamSport(event.target.value);
                }}
                sx={{width: '230px'}}
                onKeyDown={handleKeyPress}
                helperText=' '
            />
            <Button type="submit" variant='contained'>Create Team</Button>
          </form>
        ) : (
          <div>
            <p>Registration successful. Check your email for a verification code.</p>
            <div className="form-group">
            <TextField
                error={signUpError}
                id="manager-email"
                label="Verification Code"
                onChange={(event) => {
                  setVerificationCode(event.target.value);
                }}
                helperText=' '
            />
            </div>
            <Button variant='contained' onClick={handleVerifyEmail}>
              Verify Email
            </Button>
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    );
  };
  
  const signUpFieldsStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '270px',
    alignItems: 'center',
    justifyContent: 'center',
  }

  export default TeamSignUp;
  