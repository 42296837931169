import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import { useState } from 'react';
import DeleteNoticeModal from './DeleteNoticeModal';
import NoticeInput from './NoticeInput';


const TeamNotices = (props) => {
    const allowEdit = props.allowEdit ? true : false;
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [noticeIndexToDelete, setNoticeIndexToDelete] = useState(0);
    const [noticeInEdit, setNoticeInEdit] = useState(props.teamData.notices.map(() => false));

    const handleEditNotice = (index) => {
        noticeInEdit[index] = true;
        console.log(noticeInEdit);
        setNoticeInEdit([...noticeInEdit])
    }

    const handleCreateButton = () => {
        setShowCreateNew(!showCreateNew);
    };
    
    const setShowEdit = (state, index) => {
        const newNoticeInEdit = [...noticeInEdit];
        newNoticeInEdit[index] = state;
        setNoticeInEdit(newNoticeInEdit);
    }

    const setShowCreateNewInput = (state, index) => {
        setShowCreateNew(state);
    }

    const renderTeamNotice = (notice, index) => {
        return (
            !noticeInEdit[index] ? (
            <div key={index} className="notice-item">
                <p>{notice}</p>
                {allowEdit ? (
                <div className='notice-edit-buttons'>
                    <button className="delete-notice-button" onClick={() => {setShowDeleteModal(true); setNoticeIndexToDelete(index)}}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button className="edit-notice-button" onClick={() => handleEditNotice(index)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </div>
                ) : null}
            </div>
            ) : 
            <div key={index}>
                <NoticeInput
                    setShowInput={setShowEdit}
                    editIndex={index}
                    teamData={props.teamData}
                    setTeamData={props.setTeamData}
                />
            </div>
        )
    }

    return (
        <div className='team-notices-section'>
            <div className="notice-item-container">
                {props.teamData.notices.map(renderTeamNotice)}
            </div>
            {showCreateNew ? (
            <NoticeInput
                setShowInput={setShowCreateNewInput}
                teamData={props.teamData}
                setTeamData={props.setTeamData}
            />
            ) : null}
            {!showCreateNew && allowEdit ? (
            <Button variant="contained" onClick={handleCreateButton}>Create New</Button>
            ): null}
            <DeleteNoticeModal
                teamData={props.teamData}
                setTeamData={props.setTeamData}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                noticeIndex={noticeIndexToDelete}
            />
        </div>
    );
}

export default TeamNotices;