import React from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom'; // Import Router, Route, and Switch
import './App.css';
import GameLoginHome from './Pages/GameLoginHome';
import Manage from './Pages/Manage';
import PlayerUnsubscribe from './Pages/PlayerUnsubscribe.js';
import TeamPage from './Pages/TeamPage.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<GameLoginHome />} />
          <Route path=":teamId" element={<TeamPage />} />
          <Route path=":teamId/create" element={<GameLoginHome />} />
          <Route path=":teamId/manage" element={<Manage />} />
          <Route path=":teamId/unsubscribe/:playerId" element={<PlayerUnsubscribe />} />
          <Route path="*" element={<GameLoginHomeWithRedirect />} />
        </Routes>
      </div>
    </Router>
  );
}

function GameLoginHomeWithRedirect() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return <GameLoginHome />;
}

export default App;