import Button from '@mui/material/Button';
import { useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';


const LogoutComponent = ({setLogoutPopupOpen, playerToLogout, teamData, setTeamData, nextGameData, setNextGameData}) => {
    const [loggingOut, setLoggingOut] = useState(false);
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`
    const gameApiUrl = teamApiUrl + `/games/${encodeURIComponent(nextGameData.dateTimeLocKey)}`;
    const loginPlayerApiUrl = gameApiUrl + '/login/';


    const logoutPlayerOnServer = async (playerToLogout) => {
        // Make a PUT request to update the teamData on the server
        const logoutPlayerResponse = await axiosAuth.delete(loginPlayerApiUrl + encodeURIComponent(playerToLogout));
        console.log('DELETE RESPONSE', logoutPlayerResponse);

        return logoutPlayerResponse.data;
    }

    const handleLogoutPlayer = async () => {
        const retries = 3;
        let currentGameData = nextGameData;
        for (let i = 0; i < retries; i++) {
            try {
                // If previous attempt to push failed, try to update local data
                if (i > 0) {
                    const gameResponse = await axiosAuth.get(gameApiUrl + nextGameData.dateTimeLocKey);
                    console.log('GET game response:', gameResponse);
                    // If loggedIn is in old array format, convert
                    if (Array.isArray(gameResponse.data.loggedIn)) {
                        const newLoggedIn = {}
                        gameResponse.data.loggedIn.forEach((playerId) => {
                            newLoggedIn[playerId] = { group: "Home" }
                        })
                        gameResponse.data.loggedIn = newLoggedIn;
                    }
                    currentGameData = gameResponse.data;
                }

                if (!(playerToLogout in currentGameData.loggedIn)) {
                    console.log(`${playerToLogout} is already logged out`)
                    break;
                }
                else {
                    // Attempt to update team table value
                    const responseData = await logoutPlayerOnServer(playerToLogout);
                    if (responseData.gameData) {
                        setNextGameData(responseData.gameData);
                    }
                    else {
                        // Trigger update of game data by setting teamData
                        setTeamData({ ...teamData })
                    }
    
                    // Log and display a success message
                    console.log(`${playerToLogout} successfully logged out`);
                    break;
                }
            }
            catch (error) {
                console.error(`Attempt ${i+1} - Error updating teamData:`, error);
            }
        }
        setLogoutPopupOpen(false);
    }

    const handleLogoutButton = async () => {
        setLoggingOut(true);
        await handleLogoutPlayer();
        setLoggingOut(false);
    }

    return (
        <div className="logout-player-popup">
            <div className="logout-player-popup-content">
                <p>Are you sure you want to logout <strong>{teamData.players[playerToLogout]}</strong> from this game?</p>
                <Button variant="contained" onClick={() => setLogoutPopupOpen(false)}>
                    No
                </Button>
                <Button variant="contained" onClick={handleLogoutButton} disabled={loggingOut}>
                    Yes
                </Button>
            </div>
        </div>
    );
}

export default LogoutComponent;