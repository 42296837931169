import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { ReactNode, useState } from 'react';

type InfoModalProps = {
    infoHTML: ReactNode,
    sx?: React.CSSProperties 
};

const InfoModal: React.FC<InfoModalProps> = ({infoHTML, sx}) => {
    const [showInfoModal, setShowInfoModal] = useState(false);

    return (
        <div style={sx}>
          <InfoOutlinedIcon style={infoButtonStyle} onClick={() => setShowInfoModal(true)} />
          <Modal
              open={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              aria-labelledby="autologin-info-title"
              aria-describedby="autologin-info-description"
          >
              <Box sx={boxStyle}>
                {infoHTML}
              </Box>
          </Modal>
        </div>
    )
}

const infoButtonStyle = {
  color: '#007bff',
  cursor: 'pointer'
}

const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export default InfoModal;