import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, styled } from '@mui/material';
import React, { useState } from 'react';

interface ImageUploadProps {
    image?: File;
    setImage?: React.Dispatch<React.SetStateAction<File | null>>;
}

const ImageUpload: React.FC<ImageUploadProps> = ({image, setImage}) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(image !== undefined ? image : null);

    const handleRemoveButton = () => {
        setSelectedImage(null);
        if (setImage) {
            setImage(null);
        }
    }

    const handleUploadButton = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            console.log(event.target.files[0]);
            setSelectedImage(event.target.files[0]);
            if (setImage) {
                setImage(event.target.files[0]);
            }
        }  
    }

    return (
      <div>
        {selectedImage && (
            <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(selectedImage)}
            />
        )}
        <div style={buttonContainerStyle}>
            {!selectedImage ? (
            <Button sx={uploadButtonStyle} component="label" variant="contained" size='small' startIcon={<CloudUploadIcon />}>
                Upload
                <VisuallyHiddenInput 
                    type="file"
                    name="teamLogo"
                    onChange={handleUploadButton}
                />
            </Button>
            ) : (
            <Button sx={removeButtonStyle} onClick={handleRemoveButton} disabled={!selectedImage} variant="contained" size='small'>
                Remove
            </Button>
            )}
        </div>
      </div>
    );
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    marginBottom: '10px'
}

const buttonStyle: React.CSSProperties = {
    width: '90px'
}

const uploadButtonStyle: React.CSSProperties = {
    ...buttonStyle
}

const removeButtonStyle = {
    ...buttonStyle,
    backgroundColor: 'red',
    '&:hover': {
        backgroundColor: 'darkred',
    },
}

export default ImageUpload;
