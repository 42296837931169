import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';
import InfoModal from './InfoModal';


const EditPlayerInput = (props) => {
    const [teamData, setTeamData] = useState(props.teamData);
    const [editPlayerValue, setEditPlayerValue] = useState(null);
    const [editPlayerInput, setEditPlayerInput] = useState('');
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, setInputErrorMessage] = useState(' ');
    const [playerData, setPlayerData] = useState(null);
    const [playerName, setPlayerName] = useState(null);
    const [newPlayerSpare, setNewPlayerSpare] = useState(false);
    const [editPlayerNameMessage, setEditPlayerNameMessage] = useState('');
    const [editPlayerEmailMessage, setEditPlayerEmailMessage] = useState('');
    const [email, setEmail] = useState(null);
    const [cellNumber, setCellNumber] = useState(null);
    //const [editCellMessage, setEditCellMessage] = useState(' ');
    const [savePlayerMessage, setSavePlayerMessage] = useState('');
    const [savePlayerMessageStyle, setSavePlayerMessageStyle] = useState({color: 'red'});
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [confirmDeletePlayer, setConfirmDeletePlayer] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + 'teams/' + teamData.id;
    const playerApiUrl = baseApiUrl + 'teams/' + teamData.id + '/players/'

    const infoModalHTML = (
        <div className='info-content'>
            <h3 className='info-modal-title'>Spare Players</h3>
            <p>
                Players designated as spares are only able to login to games 60 hours before gametime.
            </p>
        </div>
    )


    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) =>
          option.toLowerCase().startsWith(inputValue.toLowerCase())
        );
    };

    const getPlayerId = (playerName) => {
        for (const [key, value] of Object.entries(teamData.players)) {
            if (value === playerName) {
                return key;
            }
        }
    }

    const getPlayerInfo = async (playerId) => {
        try {
            const response = await axiosAuth.get(playerApiUrl + encodeURIComponent(playerId));
            setPlayerData(response.data);
            setPlayerName(response.data.playerName);
            setEmail(response.data.email);
            setCellNumber(response.data.cellNumber);
            const playerType = response.data.playerType;
            if (playerType === 'spare') {
                setNewPlayerSpare(true);
            }
            else {
                setNewPlayerSpare(false);
            }
        } catch (error) {
            if (!error.response) {
                console.log(error);
            }
            else if (error.response.status === 404) {
                setInputError(true);
                setInputErrorMessage(`Invalid player`);
            }
            else {
                setInputError(true);
                setInputErrorMessage(`Login failed, please try again later.`);
                console.log(error);
            }
        }
    }

    const handleEdit = async () => {
        setInputError(false);
        setInputErrorMessage(' ');
        if (!editPlayerValue) {
            setInputError(true);
            setInputErrorMessage('Select a player');
            return;
        }
    
        // Get player info
        const playerId = getPlayerId(editPlayerValue);
        if (!playerId) {
            setInputError(true);
            setInputErrorMessage('Please enter a valid player');
            return
        }
        setIsLoading(true);
        await getPlayerInfo(playerId);
        setIsLoading(false);
    }

    const updatePlayerOnServer = async (newPlayerData) => {
        try {
            await axiosAuth.put(playerApiUrl + encodeURIComponent(newPlayerData.playerId), newPlayerData);
            setPlayerData(newPlayerData);
            setEditPlayerInput('');
            setSavePlayerMessageStyle({color: 'green'});
            setSavePlayerMessage('Player updated successfully')
            // Update teamData
            const response = await axiosAuth.get(teamApiUrl);
            props.setTeamData(response.data);
            setTeamData(response.data);
        } catch (error) {
            if (!error.response) {
                console.log(error);
            }
            else if (error.response.status === 404) {
                setSavePlayerMessageStyle({color: 'red'});
                setSavePlayerMessage(`Invalid player`);
            }
            else {
                setSavePlayerMessageStyle({color: 'red'});
                setSavePlayerMessage(`Save failed, please try again later.`);
                console.log(error);
            }
        }

    }

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    }

    const isInputValid = (newPlayerName, email) => {
        let inputValid = true;

        if (!newPlayerName) {
            setEditPlayerNameMessage('Please enter a name');
            inputValid = false;
        }
        if (email !== '' && !isValidEmail(email)) {
            setEditPlayerEmailMessage('Please enter a valid email');
            inputValid = false;
        }
        
        if (newPlayerName !== playerData.playerName && 
                Object.values(teamData.players).some(player => player.toLowerCase() === newPlayerName.toLocaleLowerCase())) {
            setEditPlayerNameMessage('Player name already exists on team');
            inputValid = false;
        }

        return inputValid;
    }

    const handleSave = async () => {
        setSavePlayerMessage('');
        setEditPlayerEmailMessage('');
        setEditPlayerNameMessage('');
        // Remove all white space around input and replace inner with a single space
        const newPlayerName = playerName.trim().replace(/\s+/g, ' ');
        if (isInputValid(newPlayerName, email)) {
            setIsSaving(true);
            // Create Copy of player data to update information
            const newPlayerData = {
                ...playerData,
                playerName: newPlayerName,
                email: email,
                cellNumber: cellNumber,
                playerType: newPlayerSpare ? 'spare' : 'regular'
            };
            newPlayerData.version++;

            await updatePlayerOnServer(newPlayerData);
            setIsSaving(false);
        }
    }

    const handleEditSearchSubmit = (event) => {
        event.preventDefault();
        handleEdit();
        setSavePlayerMessage('')
    }

    const deletePlayer = async (playerId) => {
        try {
            const response = await axiosAuth.delete(playerApiUrl + encodeURIComponent(playerId));
            console.log(response);
            setSavePlayerMessageStyle({color: 'green'});
            setSavePlayerMessage(`${playerData.playerName} deleted successfully`)  
            setPlayerData(null);
            // Update team data state in parent
            setTeamData(response.data.teamData);
        } catch (e) {
            console.error('Error deleting player', e);
        }
    }

    const handleDeletePlayerButton = async () => {
        setSavePlayerMessage('')
        setIsDeleting(true);
        await deletePlayer(playerData.playerId);
        setIsDeleting(false);
        setConfirmDeletePlayer(false);
    }

    return (
        <div className='edit-section'>
            <form className='edit-player-search' onSubmit={handleEditSearchSubmit}>
                <Autocomplete
                    disablePortal
                    freeSolo
                    id="edit-player-search"
                    filterOptions={filterOptions}
                    options={Object.values(teamData.players)
                        .sort((a, b) => a.localeCompare(b))}
                    inputValue={editPlayerInput}
                    onInputChange={(event, newInputValue) => {setEditPlayerInput(newInputValue)}}
                    value={editPlayerValue}
                    onChange={(event, newValue) => {
                        setEditPlayerValue(newValue);
                    }}
                    sx={{
                        width: 250
                    }}
                    renderInput={(params) => <TextField {...params} error={inputError} helperText={inputErrorMessage} label="Player Name" />}
                />
                <div className='edit-player-button'>
                    <Button variant="contained" type='submit' disabled={isLoading}>Edit</Button>
                </div>
            </form>
            {playerData ? (
            <form className='edit-player-values'>
                <h3>Editing: {playerData.playerName}</h3>
                <TextField
                    error={editPlayerNameMessage.trim() !== ''}
                    id="edit-player-name"
                    label="Player Name"
                    value={playerName}
                    onChange={(event) => {
                        setPlayerName(event.target.value);
                    }}
                    helperText={editPlayerNameMessage}
                />
                <TextField
                    error={editPlayerEmailMessage.trim() !== ''}
                    id="edit-player-email"
                    label="Player Email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                    helperText={editPlayerEmailMessage}
                />
                <TextField
                    id="edit-player-cell"
                    label="Player Cell"
                    value={cellNumber}
                    onChange={(event) => {
                        setCellNumber(event.target.value);
                    }}
                    helperText=''
                />
                <div style={spareContainerStyle}>
                    <FormControlLabel 
                        sx={spareCheckboxStyle} 
                        labelPlacement="start" 
                        control={
                            <Checkbox 
                                checked={newPlayerSpare}
                                onChange={(event) => {setNewPlayerSpare(event.target.checked)}}
                            />
                        }
                        label="Spare" 
                    />
                    <InfoModal sx={spareInfoStyle} infoHTML={infoModalHTML} />
                </div>
                {confirmDeletePlayer ? (
                <div>
                    <p style={{marginTop: '0'}}>Are you sure you want to delete <strong>{playerData.playerName}</strong>?</p>
                    <div style={editPlayerButtonContainerStyle}>
                        <Button style={editPlayerButtonStyle} variant="contained" onClick={() => setConfirmDeletePlayer(false)} disabled={isDeleting}>Cancel</Button>
                        <Button style={{...editPlayerButtonStyle, backgroundColor: 'red'}} variant="contained" onClick={handleDeletePlayerButton} disabled={isDeleting}>Delete</Button>
                    </div>
                </div>
                ): (
                <div style={editPlayerButtonContainerStyle}>
                    <Button style={{...editPlayerButtonStyle, backgroundColor: 'red'}} variant="contained" onClick={() => setConfirmDeletePlayer(true)} disabled={isSaving}>Delete</Button>
                    <Button style={editPlayerButtonStyle} variant="contained" onClick={handleSave} disabled={isSaving}>Save</Button>
                </div>
                )}
            </form>
            ) : null}
            <p style={{...savePlayerMessageStyle, margin: '10px 0'}}>{savePlayerMessage}</p>
        </div>
    )
}

const editPlayerButtonContainerStyle = {
    display: 'flex',
    gap: '60px',
    justifyContent: 'center',
}

const editPlayerButtonStyle = {
    width: '80px'
}

const spareContainerStyle = {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: '195px',
    justifySelf: 'center'
}

const spareInfoStyle = {
    display: 'flex',
    position: 'absolute',
    right : '20px',
    top: '50%',
    transform: 'translateY(-50%)',
}

const spareCheckboxStyle = {
    margin: '0'
}

export default EditPlayerInput;