import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Table, TableBody } from '@mui/material/';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';
import LoginInput from './LoginInput';
import LogoutModal from './LogoutModal';


const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: 900,
      width: "50%",
      position: "relative"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "3px 5px",
      justifyContent: "space-between",
      alignItems: "center",
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const PlayerCellStyle = {
    position: 'relative',
}

const PlayerCellTextStyle = {
    margin: "5px 0",
    display: "inline-block"
}

const GameLineup = ({teamData, setTeamData, allowLogout = false, allowGroupChange = false, showInfoButton = false}) => {
    const [nextGameData, setNextGameData] = useState(null);
    const [loadingGame, setLoadingGame] = useState(true);
    const [playerToLogout, setPlayerToLogout] = useState('');
    const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);
    const [nextGameDateTime, setNextGameDateTime] = useState(null);
    const [nextGameLocation, setNextGameLocation] = useState(null);
    const [homeTeam, setHomeTeam] = useState([]);
    const [awayTeam, setAwayTeam] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [updateStatusStr, setUpdateStatusStr] = useState('');
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`
    const gameApiUrl = teamApiUrl + '/games/';

    
    useEffect(() => {
        // Set the time to rollover to the next game to 1 hour in the future
        const currentUTC = new Date();
        setUpdateStatusStr(`${String(currentUTC.getHours()).padStart(2, '0')}:${String(currentUTC.getMinutes()).padStart(2, '0')}`);
        currentUTC.setHours(currentUTC.getHours() - 1);
        const updateTime = currentUTC.toISOString();
        let gameFound = false;
        console.log(updateTime);

        if (teamData && teamData.games) {
            for (const gameKey of teamData.games) {
                if (gameKey.split('_')[0] > updateTime) {
                    gameFound = true;
                    axiosAuth.get(gameApiUrl + encodeURIComponent(gameKey))
                        .then(response => {
                            // If loggedIn is in old array format, convert
                            if (Array.isArray(response.data.loggedIn)) {
                                const newLoggedIn = {}
                                response.data.loggedIn.forEach((playerId) => {
                                    newLoggedIn[playerId] = { group: "Home" }
                                })
                                response.data.loggedIn = newLoggedIn;
                            }
                            formatNextGameData(response.data, teamData.timeZone);
                            setNextGameData(response.data);
                            setLoadingGame(false);
                        })
                        .catch(error => {
                            console.error('Error fetching next game data:', error);
                        });
                    break;
                }
            }
            if (!gameFound) {
                setLoadingGame(false);
            }
        }
    }, [teamData, gameApiUrl]);

    useEffect(() => {
        // Set home and away teams
        if (nextGameData) {
            const homeTeam = [];
            const awayTeam = [];
            
            Object.keys(nextGameData.loggedIn).forEach((playerId) => {
                if (nextGameData.loggedIn[playerId].group === "Home") {
                    homeTeam.push(playerId);
                }
                else {
                    awayTeam.push(playerId);
                }
            })
            if (homeTeam.length > 1) {
                homeTeam.sort((a, b) => teamData.players[a].localeCompare(teamData.players[b]));
            }
            if (awayTeam.length > 1) {
                awayTeam.sort((a, b) => teamData.players[a].localeCompare(teamData.players[b]));
            }
            setHomeTeam(homeTeam);
            setAwayTeam(awayTeam);
            
            setLoadingGame(false);
        }
    }, [nextGameData, teamData]);

    const updateTeamData = async () => {
        try {
            const teamGetResponse = await axiosAuth.get(teamApiUrl);
            console.log(teamGetResponse);
            setTeamData(teamGetResponse.data);
            const currentUTC = new Date();
            setUpdateStatusStr(`${String(currentUTC.getHours()).padStart(2, '0')}:${String(currentUTC.getMinutes()).padStart(2, '0')}`);
        } catch (err) {
            console.error(err);
        }
    }

    const getPlayerCellTextDivStyle = () => {
        if (homeTeam && awayTeam && homeTeam.length > 0 && awayTeam.length > 0) {
            return {
                display: 'flex', 
                justifyContent: "space-between",
                marginLeft: "0", 
                gap: "3px",
                alignItems: "center"
            };
        } else {
            return {
                display: 'flex', 
                justifyContent: "space-between",
                marginLeft: "10px", 
                gap: "15px",
                alignItems: "center"
            };
        }
    };

    const formatNextGameData = (nextGameData, timeZone) => {
        setNextGameLocation(nextGameData.dateTimeLocKey.split('_')[1])

        const utcDateObj = new Date(nextGameData.dateTimeLocKey.split('_')[0]);
        const options = {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: timeZone
          };
        setNextGameDateTime(utcDateObj.toLocaleString('en-US', options));
    }

    const handleLogoutPlayerButton = (playerId) => {
        setPlayerToLogout(playerId);
        setLogoutPopupOpen(true);
    }

    const swapPlayerOnServer = async (updatedGameData) => {
        try{
            await axiosAuth.put(gameApiUrl + encodeURIComponent(nextGameData.dateTimeLocKey), updatedGameData);
        } catch (error) {
            console.error('Error updating gameData:', error);
            setTeamData({...teamData});
        }
    }

    const handleSwapPlayerButton = async (playerId) => {
        const updatedGameData = {
            ...nextGameData,
            version: nextGameData.version + 1
        };
        if (updatedGameData.loggedIn[playerId].group === "Home") {
            updatedGameData.loggedIn[playerId].group = "Away";
        } else {
            updatedGameData.loggedIn[playerId].group = "Home";
        }

        swapPlayerOnServer(updatedGameData);

        setNextGameData(updatedGameData);
    };

    const formatPlayerName = (playerId) => {
        let playerName = teamData.players[playerId];
        let maxCharacters;
        if (homeTeam.length > 0 && awayTeam.length > 0) {
            if (allowGroupChange || allowLogout) {
                maxCharacters = 14;
            } else {
                maxCharacters = 16;
            }
            
        }
        else {
            maxCharacters = 25;
        }
        
        if (playerName.length > maxCharacters) {
            playerName = playerName.substring(0, maxCharacters - 3) + '...';
        }

        return playerName;
    }

    const renderPlayerCell = (group, index) => {
        if (group.length === 0) {
            return null;
        }
        else if (group.length <= index) {
            return (
                <StyledTableCell component="th" scope="row" sx={PlayerCellStyle}>
                    <p style={PlayerCellStyle}> </p>
                </StyledTableCell>
            );
        }

        return (
            <StyledTableCell component="th" scope="row" sx={PlayerCellStyle}>
                <div style={{display:"flex", justifyContent:"space-between", alignContent:"center"}}>
                    <div style={getPlayerCellTextDivStyle()}>
                        <p style={{ ...PlayerCellTextStyle, width:'16px'}}>{index+1}</p>
                        <p style={PlayerCellTextStyle}>
                        {formatPlayerName(group[index])}
                        </p>
                    </div>
                    <div className='login-table-edit-buttons'>
                        <Tooltip id="swap-player-teams-tt" />
                        <Tooltip id="logout-player-tt" />   
                        {allowGroupChange ? ( 
                        <CompareArrowsIcon data-tooltip-id='swap-player-teams-tt' data-tooltip-content='Swap Teams' className='swap-player-button' onClick={() => handleSwapPlayerButton(group[index])}/>
                        ) : null
                        }
                        {allowLogout ? (
                        <LogoutIcon data-tooltip-id='logout-player-tt' data-tooltip-content='Logout' className='logout-player-button' onClick={() => handleLogoutPlayerButton(group[index])}/>
                        ) : null
                        }
                    </div>
                </div>
            </StyledTableCell>
        );
    }


    return (
        <div id='login-container'>
            {!loadingGame ? (
            nextGameData ? (
            <div className='game-data'>
                <p className='next-game-header'>
                    Next Game
                </p>
                <p className='next-game-info'>
                    {nextGameDateTime}
                    <br />
                    {nextGameLocation}
                </p>
                {nextGameData.opponent ? (
                <div className='opponent-container'>
                    <p className='next-game-header'>
                        vs
                    </p>
                    <p className='next-game-info'>
                        {nextGameData.opponent}
                    </p>
                </div>
                ) : null}
                <br />
                <div style={statusContainerStyle}>
                    <p style={statusTextStyle}>Status as of {updateStatusStr}</p>
                    <RefreshIcon style={statusRefreshButtonStyle} onClick={updateTeamData}/>
                </div>
                <br />
                {Object.keys(nextGameData.loggedIn).length < teamData.maxPlayers ? (
                <LoginInput 
                    playerOptions={
                        Object.keys(teamData.players)
                            .filter((playerId) => !(playerId in nextGameData.loggedIn))
                            .sort((a, b) => teamData.players[a].localeCompare(teamData.players[b]))
                            .map((playerId) => teamData.players[playerId])}
                    teamData={teamData}
                    nextGameData={nextGameData}
                    setTeamData={setTeamData}
                    updateTeamData={updateTeamData}
                />
                ) : (
                    <h4 id='benches-full-msg'>BENCHES ARE FULL</h4>
                )}
                <TableContainer sx={{margin: "10px auto", width: "315px"}} component={Paper}>
                    <Table sx={{ margin: 0}} size='small' aria-label="login-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" colSpan={2}>
                                    <p id="loggedin-table-title">Players Logged In</p>
                                    <p id="max-players-header">Max {teamData.maxPlayers}</p>
                                    {showInfoButton ? (
                                    <InfoOutlinedIcon id='gamelineup-info-button' onClick={() => setShowInfoModal(true)}/>
                                    ) : null
                                    }
                                </StyledTableCell>
                            </TableRow>
                            {homeTeam.length > 0 && awayTeam.length > 0 ? (
                            <TableRow>
                                <StyledTableCell align="center" colSpan={1}>
                                    Home
                                </StyledTableCell>
                                <StyledTableCell align="center" colSpan={1}>
                                    Away
                                </StyledTableCell>
                            </TableRow>
                            ) : (
                                null
                            )}
                        </TableHead>
                        {nextGameData.loggedIn && Object.keys(nextGameData.loggedIn).length > 0 ? (
                        <TableBody>
                            {Array.from({length: Math.max(homeTeam.length, awayTeam.length)}, (_, index) => (
                            <StyledTableRow key={index} className='login-table-row'>
                                {renderPlayerCell(homeTeam, index)}
                                {renderPlayerCell(awayTeam, index)}
                            </StyledTableRow>
                            ))}
                        </TableBody>
                        ) : (
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    No Players Logged In
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                        )}
                    </Table>
                </TableContainer>
                
                {logoutPopupOpen ? (
                <LogoutModal
                    setLogoutPopupOpen={setLogoutPopupOpen}
                    playerToLogout={playerToLogout}
                    teamData={teamData}
                    setTeamData={setTeamData}
                    nextGameData={nextGameData}
                    setNextGameData={setNextGameData}
                />
                ) : null} 
            </div>
            ) : (
                <p>No Upcoming Games</p>
            )) : (
                <p>Loading Game</p>
            )}
            <Modal
                open={showInfoModal}
                onClose={() => setShowInfoModal(false)}
                aria-labelledby="gamelineup-info-title"
                aria-describedby="gamelineup-info-description"
            >
                <Box sx={boxStyle}>
                    <h3 className='info-modal-title'>Game Lineup Info</h3>
                    <p>
                        <br/>
                        Tap or hover over a player's name to view available actions.
                        <br/>
                    </p>
                </Box>
            </Modal>
        </div>
    );

}

const statusContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
}
const statusTextStyle = {
    margin: '0',
    fontSize: '12px',
    alignSelf: 'center'
}
const statusRefreshButtonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '1px 1px',
    borderRadius: '3px',
    cursor: 'pointer',
    height: '0.8em',
    width: '0.8em',
}

export default GameLineup;