import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import config from '../config.json';

const environment = process.env.REACT_APP_ENV;
const userPoolId = environment && environment in config ? config[environment].userPoolId : config.dev.userPoolId;
const poolClientId = environment && environment in config ? config[environment].poolClientId : config.dev.poolClientId;
const region = 'us-east-2';

const client = new CognitoIdentityProviderClient({
    region
});

export { client, poolClientId, userPoolId };

