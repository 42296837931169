import React from 'react';
import CreateGame from '../Components/CreateGame';
import UpcomingGames from './UpcomingGames';


const ManageGames = ({teamData, setTeamData}) => {

    return (
        <div className='manage-games-container'>
            <CreateGame
                teamData={teamData}
                setTeamData={setTeamData}
            />
            <UpcomingGames 
                teamData={teamData}
                setTeamData={setTeamData}
                allowEdit
                showUpcomingGame
            />
        </div>
    );

};

export default ManageGames;