import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';
import InfoModal from './InfoModal';


const AutoLogin = (props) => {
    const [teamData, setTeamData] = [props.teamData, props.setTeamData];
    const [playerInput, setPlayerInput] = useState('');
    const [playerValue, setPlayerValue] = useState(null);
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, setInputMessage] = useState(' ');
    const [helperTextColor, setHelperTextColor] = useState('red');
    const [isAdding, setIsAdding] = useState(false);
    const [showRemovePlayerModal, setShowRemovePlayerModal] = useState(false);
    const [playerToRemove, setPlayerToRemove] = useState('');
    const [removingPlayer, setRemovingPlayer] = useState(false);
    
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + 'teams/' + teamData.id;
    const autologinApiUrl = teamApiUrl + '/autologin/';

    const infoModalHTML = (
        <div className='info-content'>
            <h3 className='info-modal-title'>Auto-Login Info</h3>
            <p>
                <br/>
                Auto-Login allows team managers to set a list of players to be automatically logged in for all future games.
                <br/>
                <br/>
                The maximum number of players = the maximum players allowed to log in for any game.
            </p>
        </div>
    )

    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) =>
          option.toLowerCase().startsWith(inputValue.toLowerCase())
        );
    };

    const printErrorMessage = (msg) => {
        setInputError(true);
        setInputMessage(msg);
        setHelperTextColor('red');
    }

    const printSuccessMessage = (msg) => {
        setInputError(false);
        setInputMessage(msg);
        setHelperTextColor('green');
    }

    const addPlayerToAutoLogin = async (playerId) => {

        const retries = 3;
        for (let i = 0; i < retries; i++) {
            try {
                let teamGetResponse = await axiosAuth.get(teamApiUrl);
                let newTeamData = { ...teamGetResponse.data };
                setTeamData(newTeamData);

                if (newTeamData.autoLoginPlayers && newTeamData.autoLoginPlayers.includes(playerId)) {
                    printErrorMessage('Player is already on the Auto-Login list');
                    break;
                } else if (newTeamData.autoLoginPlayers && newTeamData.autoLoginPlayers.length >= teamData.maxPlayers) {
                    printErrorMessage('Auto-Login list is already at maximum capacity');
                    break;
                }

                await axiosAuth.post(autologinApiUrl + encodeURIComponent(playerId), {});
                teamGetResponse = await axiosAuth.get(teamApiUrl);
                newTeamData = { ...teamGetResponse.data };
                setTeamData(newTeamData);
                printSuccessMessage(`Added ${newTeamData.players[playerId]} to Auto-Login list`);
                break;
            } catch (error) {
                if (!error.response) {
                    console.log(error);
                }
                else {
                    printErrorMessage('Login failed, please try again later.')
                    console.log(error);
                }
            }
        }
    }

    const removePlayerFromAutoLogin = async (playerId) => {
        const retries = 3;
        for (let i = 0; i < retries; i++) {
            try {
                let teamGetResponse = await axiosAuth.get(teamApiUrl);
                let newTeamData = { ...teamGetResponse.data };
                setTeamData(newTeamData);

                if (newTeamData.autoLoginPlayers && !newTeamData.autoLoginPlayers.includes(playerId)) {
                    printErrorMessage('Player is not on the Auto-Login list');
                    break;
                }

                await axiosAuth.delete(autologinApiUrl + encodeURIComponent(playerId), {});
                teamGetResponse = await axiosAuth.get(teamApiUrl);
                newTeamData = { ...teamGetResponse.data };
                setTeamData(newTeamData);
                printSuccessMessage(`Removed '${newTeamData.players[playerId]}' from Auto-Login list`);
                break;
            } catch (error) {
                if (!error.response) {
                    console.log(error);
                }
                else {
                    printErrorMessage('Login failed, please try again later.')
                    console.log(error);
                }
            }
        }
    }

    const getPlayerId = (playerName) => {
        for (const [key, value] of Object.entries(teamData.players)) {
            if (value === playerName) {
                return key;
            }
        }
    }

    const handleAdd = async (event) => {
        event.preventDefault();
        printSuccessMessage(' ');
        if (!playerValue) {
            printErrorMessage('Select a player');
            return;
        }

        const playerId = getPlayerId(playerValue);
        if (!(playerId)) {
            printErrorMessage('Please enter a valid player');
            return;
        }

        setIsAdding(true);
        // Add to auto login
        await addPlayerToAutoLogin(playerId);
        setIsAdding(false);
    }

    const handleRemove = async () => {
        printSuccessMessage(' ');

        setRemovingPlayer(true);
        // Remove from auto login
        try {
            await removePlayerFromAutoLogin(playerToRemove);
        } catch (e) {
            console.error('Error removing player', e);
        }
        setRemovingPlayer(false);
        setShowRemovePlayerModal(false);
    }

    const handleRemovePlayerButton = (playerId) => {
        printSuccessMessage(' ');
        setPlayerToRemove(playerId);
        setShowRemovePlayerModal(true);
    }

    return (
        <div id='autologin-container'>
            <form className='autologin-player-search' onSubmit={handleAdd}>
                <Autocomplete
                    disablePortal
                    freeSolo
                    id="autologin-player-search"
                    filterOptions={filterOptions}
                    options={Object.values(teamData.players)
                        .sort((a, b) => a.localeCompare(b))}
                    inputValue={playerInput}
                    onInputChange={(event, newInputValue) => {setPlayerInput(newInputValue)}}
                    value={playerValue}
                    onChange={(event, newValue) => {
                        setPlayerValue(newValue);
                    }}
                    sx={{
                        width: 250
                    }}
                    renderInput={(params) => <TextField {...params} error={inputError} FormHelperTextProps={{style: {color: helperTextColor}}} helperText={inputErrorMessage} label="Player Name" />}
                />
                <div className='autologin-player-button'>
                    <Button variant="contained" type='submit' disabled={isAdding}>
                        ADD
                    </Button>
                </div>
            </form>
            <TableContainer sx={{margin: "10px auto", width: "315px"}} component={Paper}>
                <Table sx={{ width: 314, margin: 0}} size='small' aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center" colSpan={3}>
                                <p id='autologin-table-title'>Auto-Login Players</p>
                                <p id='autologin-table-mp'>Max {teamData.maxPlayers}</p>
                                <InfoModal sx={infoModalStyle} infoHTML={infoModalHTML}/>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    
                    {teamData.autoLoginPlayers && teamData.autoLoginPlayers.length > 0 ? (
                    <TableBody>
                        {teamData.autoLoginPlayers.map((id, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                                {index+1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {teamData.players[id]}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                <Tooltip id="remove-al-player-tt" />
                                <PersonRemoveIcon data-tooltip-id='remove-al-player-tt' data-tooltip-content='Remove Player' className='remove-autologin-player' onClick={() => handleRemovePlayerButton(id)}/>
                            </StyledTableCell>
                        </StyledTableRow>
                        ))}
                    </TableBody>
                    ) : (
                    <TableBody>
                        <StyledTableCell component="th" scope="row">
                            None
                        </StyledTableCell>
                    </TableBody>
                    )}
                </Table>
            </TableContainer>
            <Modal
                open={showRemovePlayerModal}
                onClose={() => setShowRemovePlayerModal(false)}
                aria-labelledby="remove-player-popup"
                aria-describedby="remove-player-description"
            >
                <Box sx={boxStyle}>
                    <h3 className='info-modal-title'>Confirm Removal</h3>
                    <p>
                        <br/>
                        Are you sure you would like to remove <strong>{teamData.players[playerToRemove]}</strong> from the Auto-Login list ?
                        <br/>
                    </p>
                    <div style={RemoveModalButtonContainerStyle}>
                        <Button style={RemoveModalButtonStyle} onClick={() => setShowRemovePlayerModal(false)} variant="contained">Cancel</Button>
                        <Button style={{...RemoveModalButtonStyle, backgroundColor: 'red'}} variant="contained" disabled={removingPlayer} onClick={handleRemove}>Remove</Button> 
                    </div>
                </Box>
            </Modal>
        </div>
    );
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontWeight: 900,
      position: 'relative',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const RemoveModalButtonContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px'
}

const RemoveModalButtonStyle = {
    width: '90px'
}

const infoModalStyle = {
    display: 'flex',
    position: 'absolute',
    right : '40px',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '10px'
}

const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export default AutoLogin;