import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AutoLogin from '../Components/AutoLogin';
import ConfigurePlayer from '../Components/ConfigurePlayer';
import ConfigureTeamComponent from '../Components/ConfigureTeamComponent';
import EditPlayerInput from '../Components/EditPlayerInput';
import GameLineup from '../Components/GameLineup';
import ManageGames from '../Components/ManageGames';
import TeamNotices from '../Components/TeamNotices';
import LoadingGif from '../assets/loading.gif';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';


const Manage = () => {
    const navigate = useNavigate();
    const { teamId } = useParams();
    const [teamData, setTeamData] = useState(null);
    const [addPlayerSection, setAddPlayerSection] = useState(false);
    const [addPlayerTitle, setAddPlayerTitle] = useState('+ Add Player');
    const [editPlayerSection, setEditPlayerSection] = useState(false);
    const [editPlayerTitle, setEditPlayerTitle] = useState('+ Edit Player');
    const [gameLineupSection, setGameLineupSection] = useState(false);
    const [gameLineupTitle, setGameLineupTitle] = useState('+ Game Lineup');
    const [createGameSection, setCreateGameSection] = useState(false);
    const [createGameTitle, setCreateGameTitle] = useState('+ Games');
    const [authenticated, setAuthenticated] = useState(false);
    const [teamNoticeSection, setTeamNoticeSection] = useState(false);
    const [teamNoticeTitle, setTeamNoticeTitle] = useState('+ Team Notices');
    const [autoLoginSection, setAutoLoginSection] = useState(false);
    const [autoLoginTitle, setAutoLoginTitle] = useState('+ Auto-Login');
    const [configurationSection, setConfigurationSection] = useState(false);
    const [configurationTitle, setConfigurationTitle] = useState('+ Configuration');

    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamId}`


    useEffect(() => {
        if (!localStorage.accessToken || !localStorage.idToken || !localStorage.refreshToken || !localStorage.teamId) {
            console.log('tokens not present');
            navigate('/');
        }
        else if (localStorage.teamId !== teamId) {
            localStorage.clear();
            console.log('tokens not valid for requested team');
            navigate('/');
        }
        else {
            setAuthenticated(true);
            console.log('tokens present');
        }
    }, [navigate, teamId]);

    // useEffect hook to get the team data on every refresh of the page in order to keep the team information up to date
    useEffect(() => {
        axiosAuth.get(teamApiUrl)
            .then(response => {
                setTeamData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [teamApiUrl]);

    const toggleAddPlayerSection = () => {
        if (addPlayerSection) {
            setAddPlayerSection(false);
            setAddPlayerTitle('+ Add Player');
        } else {
            setAddPlayerSection(true);
            setAddPlayerTitle('- Add Player');
        }
    };

    const toggleEditPlayerSection = () => {
        if (editPlayerSection) {
            setEditPlayerSection(false);
            setEditPlayerTitle('+ Edit Player');
        } else {
            setEditPlayerSection(true);
            setEditPlayerTitle('- Edit Player');
        }
    };

    const toggleGameLineupSection = () => {
        if (gameLineupSection) {
            setGameLineupSection(false);
            setGameLineupTitle('+ Game Lineup');
        } else {
            setGameLineupSection(true);
            setGameLineupTitle('- Game Lineup');
        }
    };

    const toggleCreateGameSection = () => {
        if (createGameSection) {
            setCreateGameSection(false);
            setCreateGameTitle('+ Games');
        } else {
            setCreateGameSection(true);
            setCreateGameTitle('- Games');
        }
    };

    const toggleTeamNoticeSection = () => {
        if (teamNoticeSection) {
            setTeamNoticeSection(false);
            setTeamNoticeTitle('+ Team Notices');
        } else {
            setTeamNoticeSection(true);
            setTeamNoticeTitle('- Team Notices');
        }
    };

    const toggleAutoLoginSection = () => {
        if (autoLoginSection) {
            setAutoLoginSection(false);
            setAutoLoginTitle('+ Auto-Login');
        } else {
            setAutoLoginSection(true);
            setAutoLoginTitle('- Auto-Login');
        }
    };

    const toggleConfigurationSection = () => {
        if (configurationSection) {
            setConfigurationSection(false);
            setConfigurationTitle('+ Configuration');
        } else {
            setConfigurationSection(true);
            setConfigurationTitle('- Configuration');
        }
    }

    return ( 
        <div>
            {authenticated && teamData ? (
            <div className="container">
                <h1>{teamData.name}</h1>
                <h2>Management</h2>
                <div className='top-section-header'>
                
                    <h2 className="section-title" onClick={toggleConfigurationSection}>
                        {configurationTitle}
                    </h2>
                    {configurationSection ? (
                    <ConfigureTeamComponent
                        teamData={teamData}
                        setTeamData={setTeamData}
                    />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleAddPlayerSection}>
                        {addPlayerTitle}
                    </h2>
                    {addPlayerSection ? (
                        <ConfigurePlayer 
                            teamData={teamData}
                            setTeamData={setTeamData}
                        />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleEditPlayerSection}>
                        {editPlayerTitle}
                    </h2>
                    {editPlayerSection ? (
                    <EditPlayerInput
                        teamData={teamData}
                        setTeamData={setTeamData}
                    />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleAutoLoginSection}>
                        {autoLoginTitle}
                    </h2>
                    {autoLoginSection ? (
                    <AutoLogin
                        teamData={teamData}
                        setTeamData={setTeamData}
                    />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleGameLineupSection}>
                        {gameLineupTitle}
                    </h2>
                    {gameLineupSection ? (
                    <GameLineup
                        teamData={teamData}
                        setTeamData={setTeamData}
                        allowLogout
                        allowGroupChange
                    />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleCreateGameSection}>{createGameTitle}</h2>
                    {createGameSection ? (
                        <ManageGames
                            teamData={teamData}
                            setTeamData={setTeamData}
                        />
                    ) : null}
                </div>
                <div className='section-header'>
                    <h2 className="section-title" onClick={toggleTeamNoticeSection}>{teamNoticeTitle}</h2>
                    {teamNoticeSection ? (
                        <div>
                            <TeamNotices
                                teamData={teamData}
                                setTeamData={setTeamData}
                                allowEdit
                            />
                            <br />
                        </div>
                    ):  null}
                </div>
                <div>
                    <Button variant='outlined' style={teamNavButtonStyle} onClick={() => navigate(`/${teamId}`)}>Team Page</Button>
                </div>
            </div>
            ) : (
            <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={LoadingGif} alt="Loading..." style={{width: '100px'}}/>
            </div>
            )}
        </div>
    )
};

const teamNavButtonStyle = {
    margin: '20px 0'
}

export default Manage;