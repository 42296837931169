import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';


const PlayerUnsubscribe = () => {
    const { teamId, playerId } = useParams();
    const navigate = useNavigate();
    const [ unsubscribeMessage, setUnsubscribeMessage ] = useState('Unsubscribing ...');

    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const unsubApiUrl = baseApiUrl + `teams/${teamId}/unsubscribe/${playerId}`;

    useEffect(() => {
        axiosAuth.put(unsubApiUrl)
            .then(response => {
                setUnsubscribeMessage(response.data.unsubscribeMessage);
            })
            .catch(error => {
                console.error('Error putting data:', error);
                navigate('/');
            });
    }, [teamId, playerId, unsubApiUrl, navigate]);

    return (
        <div>
            <h1>{unsubscribeMessage}</h1>
        </div>
    )
}

export default PlayerUnsubscribe;