import { Box, Button, FormControlLabel, FormHelperText, Switch, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';
import ImageUpload from './ImageUpload';


const ConfigureTeamComponent = ({ teamData, setTeamData }) => {
    const [allowLogout, setAllowLogout] = useState(false);
    const [teamConfMessage, setTeamConfMessage] = useState(' ');
    const [teamConfMessageStyle, setTeamConfMessageStyle] = useState({color: 'red'});
    const [isSaving, setIsSaving] = useState(false);
    const [dataToUpdate, setDataToUpdate] = useState(false);
    const [maxPlayersError, setMaxPlayersError] = useState(false);
    const [maxPlayersInput, setMaxPlayersInput] = useState('0');
    const [teamLogo, setTeamLogo] = useState(null);
    const [inputTeamLogo, setInputTeamLogo] = useState(null);
    const [loadingTeamLogo, setLoadingTeamLogo] = useState(true);
    const [loadingConfigs, setLoadingConfigs] = useState(true);

    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`
    const teamLogoApiUrl = teamApiUrl + '/logo'


    useEffect(() => {
        setAllowLogout(teamData.allowLogout || false);
        setMaxPlayersInput(teamData.maxPlayers);
        // Set current logo to teamLogo and inputTeam logo state variable
        axiosAuth.get(teamLogoApiUrl)
            .then(response => {
                const signedUrl = response.data.signedUrl;
                console.log(signedUrl);
                axios.get(signedUrl, { responseType: 'blob' })
                    .then(response => {
                        const teamLogo = new File([response.data], 'downloaded_logo.png', { type: response.headers['content-type'] });
                        console.log('Downloaded logo as File:', teamLogo);
                        setTeamLogo(teamLogo);
                        setInputTeamLogo(teamLogo);
                        setLoadingTeamLogo(false);
                    })
                    .catch(error => {
                        console.log('Error downloading file:', error);
                    });
            }).catch(error => {
                if (error.response.status === 404) {
                    console.log('No team logo set');
                    setLoadingTeamLogo(false);
                } else {
                    console.log('Error getting signed URL:', error);
                }
            });
    }, [teamData.allowLogout, teamData.maxPlayers, teamLogoApiUrl]);

    useEffect(() => {
        if (!loadingTeamLogo) {
            setLoadingConfigs(false);
        }
    }, [loadingTeamLogo])

    const printUnsuccesfulTeamConfMessage = (msg) => {
        setTeamConfMessage(msg);
        setTeamConfMessageStyle({ color: 'red' });
    }

    const printSuccesfulTeamConfMessage = (msg) => {
        setTeamConfMessage(msg);
        setTeamConfMessageStyle({ color: 'green' });
    }

    const handleTeamLogoInput = (newTeamLogo) => {
        setInputTeamLogo(newTeamLogo);
        checkDataToUpdate(allowLogout, maxPlayersInput, newTeamLogo);
    }

    const handleAllowLogoutSwitchChange = (event) => {
        setAllowLogout(event.target.checked);
        checkDataToUpdate(event.target.checked, maxPlayersInput, inputTeamLogo);
    };

    const handleMaxPlayersInputChange = (event) => {
        setMaxPlayersInput(event.target.value);
        if (!event.target.value) {
            console.log('no value');
            setMaxPlayersError(true);
            setDataToUpdate(false);
            return;
        }
        else if (parseInt(event.target.value) > 100) {
            setMaxPlayersError(true);
            setDataToUpdate(false);
            return;
        }
        setMaxPlayersError(false);
        checkDataToUpdate(allowLogout, parseInt(event.target.value), inputTeamLogo);
    };

    const checkDataToUpdate = (newAllowLogout, newMaxPlayers, newTeamLogo) => {
        if (newAllowLogout !== teamData.allowLogout || newMaxPlayers !== teamData.maxPlayers || newTeamLogo !== teamLogo) {
            setDataToUpdate(true);
        }
        else {
            setDataToUpdate(false);
        }
    }

    const updateTeamDataOnServer = async (updatedFields) => {
        const retries = 3;

        if (inputTeamLogo !== teamLogo) {
            try {
                // If team logo removed, delete on server
                if (!inputTeamLogo) {
                    const logoDeleteResponse = await axiosAuth.delete(teamLogoApiUrl);
                    console.log(logoDeleteResponse);
                } else {
                    const formData = new FormData();
                    formData.append('teamLogo', inputTeamLogo);
                    const logoPostResponse = await axiosAuth.post(teamLogoApiUrl);
                    console.log(logoPostResponse);
                    const putObjectResponse = await axios.put(logoPostResponse.data.signedUrl, inputTeamLogo, {
                        headers: {
                            'Content-Type': inputTeamLogo.type, // Set the correct content type
                        },
                    });
                    console.log(putObjectResponse);
                }
                setTeamLogo(inputTeamLogo);
            } catch (error) {
                console.error('Error while updating team logo:', error);
                return;
            }
        }

        let updatedTeamData = {
            ...teamData,
            ...updatedFields,
        }
        for (let i = 0; i < retries; i++) {
            try {
                if (i > 0) {
                    let teamGetResponse = await axiosAuth.get(teamApiUrl);
                    updatedTeamData = {
                        ...teamGetResponse.data,
                        ...updatedFields
                    };
                }
                updatedTeamData.version++;

                const teamPutResponse = await axiosAuth.put(teamApiUrl, updatedTeamData);
                console.log(teamPutResponse);
                setTeamData(updatedTeamData);
                printSuccesfulTeamConfMessage('Team configuration saved');
                setDataToUpdate(false);
                break;
            } catch (error) {
                if (i === retries - 1) {
                    printUnsuccesfulTeamConfMessage('Save failed, please try again later.')
                }
                console.log(error);
            }
        }
    };

    const handleSave = async () => {
        setTeamConfMessage(' ');

        if (parseInt(maxPlayersInput) < teamData.autoLoginPlayers.length) {
            printUnsuccesfulTeamConfMessage('Maximum number of players must be greater than the number of autologin players.');
            setMaxPlayersError(true);
            return;
        }
        setIsSaving(true);
        
        const updatedFields = {
            allowLogout: allowLogout,
            maxPlayers: parseInt(maxPlayersInput)
        }

        await updateTeamDataOnServer(updatedFields);
        setIsSaving(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave();
    };


return (
    <div>
    {loadingConfigs ? (
        <h4>Loading...</h4>
    ) : (
    <form className="configure-team-form" onSubmit={handleSubmit}>
        <FormControlLabel
        sx={configItemStyle}
        labelPlacement="start"
        control={
            <Box display="flex" alignItems="center">
                <TextField
                    error={maxPlayersError}
                    style={{ width: '70px', height: '40px'}}
                    type="number"
                    variant="outlined"
                    size="small"
                    value={maxPlayersInput}
                    onChange={handleMaxPlayersInputChange}
                    InputProps={{
                        inputProps: {
                            min: 1,    // Set your desired minimum value
                            step: 1   // Set the desired interval
                        }
                    }}
                />
            </Box>
        }
        label="Max Players:"
        />
        <FormControlLabel
            sx={configItemStyle}
            labelPlacement="start"
            control={
            <Switch
                checked={allowLogout}
                onChange={handleAllowLogoutSwitchChange}
                name="allowPlayerLogout"
                color="primary"
            />
            }
            label="Allow Player Logout:"
        />
        <h4 style={{margin: '10px 0'}}>Team Logo</h4>
        <ImageUpload
            image={inputTeamLogo}
            setImage={handleTeamLogoInput}
        />
        <Button type='submit' variant="contained" color="primary" disabled={!dataToUpdate || isSaving}>
            Save
        </Button>
        <FormHelperText sx={{ ...teamConfMessageStyle, ...messageStyle }}>
            {teamConfMessage}
        </FormHelperText>
    </form>
    )}
    </div>
);
}

const configItemStyle = {
    width: '250px',
    margin: '0',
    justifyContent: 'space-between',
    display: 'inline-flex'
}

const messageStyle = {
    margin: '5px 10px',
    fontSize: '1rem', 
    textAlign: 'center'

}

export default ConfigureTeamComponent;