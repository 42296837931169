import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';


const NoticeInput = ({setShowInput, editIndex, teamData, setTeamData}) => {
    const [inputText, setInputText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [createErrorMessage, setCreateErrorMessage] = useState('');


    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`

    useEffect(() => {
        console.log('in use effect');
        if (teamData && editIndex !== undefined) {
            console.log('in edit');
            setInputText(teamData.notices[editIndex]);
        }
    }, [editIndex, teamData]);

    const handleTextChange = (event) => {
        setInputText(event.target.value);
    };

    const handleDiscardClick = () => {
        setInputText('');
        setShowInput(false, editIndex);
    };

    const handleSaveClick = async () => {
        setIsSaving(true);
        const newTeamData = { ...teamData };
        newTeamData.notices = [ ...teamData.notices]
        newTeamData.version++;
        if (editIndex === undefined) {
            newTeamData.notices.push(inputText);
        } else {
            newTeamData.notices[editIndex] = inputText;
        }

        // Update the team data on server
        await updateTeamNoticesOnServer(newTeamData);
        setIsSaving(false);
    };

    const updateTeamNoticesOnServer = async (newTeamData) => {
        try{
            await axiosAuth.put(teamApiUrl, newTeamData);

            setTeamData(newTeamData);
            setShowInput(false, editIndex);
            setInputText('');
        } catch (error) {
            if (!error.response) {
                console.log(error);
            }
            else if (error.response.status === 404) {
                setCreateErrorMessage(`Team not found`);
            }
            else {
                setCreateErrorMessage(`Notice update failed, please try again later.`);
                console.log(error);
            }
        }
    }

    return (
    <div className='create-new-notice'>
        <textarea
            rows="10"
            cols="50"
            value={inputText}
            onChange={handleTextChange}
            placeholder="Enter your text here"
        />
        <div className='new-notice-buttons'>
            <Button variant='contained' onClick={handleDiscardClick} style={{ backgroundColor: 'red'}}>Discard</Button>
            <Button variant='contained' onClick={handleSaveClick} disabled={isSaving}>Save</Button>
        </div>
        {createErrorMessage &&
        <p style={{color: 'red'}}>{createErrorMessage}</p>
        }
    </div>
    );
}

export default NoticeInput;