import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TeamData } from '../types/index';
import axiosAuth from '../utils/axiosAuth';
import InfoModal from './InfoModal';

interface ConfigurePlayerInput {
    teamData: TeamData;
    setTeamData: Function;
    playerName?: string;
    playerEmail?: string;
    playerCell?: string;
    playerSpare?: boolean;
}

const ConfigurePlayer: React.FC<ConfigurePlayerInput> = (
        {teamData, setTeamData, playerName, playerEmail, playerCell, playerSpare}) => {
    const [newPlayerName, setNewPlayerName] = useState(playerName ? playerName : '');
    const [newPlayerEmail, setNewPlayerEmail] = useState(playerEmail ? playerEmail : '');
    const [newPlayerCell, setNewPlayerCell] = useState(playerCell ? playerCell : '');
    const [newPlayerSpare, setNewPlayerSpare] = useState(playerSpare != null ? playerSpare : false);
    const [configurePlayerMessage, setConfigurePlayerMessage] = useState('');
    const [configurePlayerMessageStyle, setConfigurePlayerMessageStyle] = useState({color: 'red'});
    const [isConfiguringPlayer, setIsConfiguringPlayer] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const teamApiUrl = `/teams/${teamData.id}`;
    const playerApiUrl = teamApiUrl + '/players/';

    const infoModalHTML = (
        <div className='info-content'>
            <h3 className='info-modal-title'>Spare Players</h3>
            <p>
                Players designated as spares are only able to login to games 60 hours before gametime.
            </p>
        </div>
    )


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const printUnsuccesfulConfigurePlayerMessage = (msg: string) => {
        setConfigurePlayerMessage(msg);
        setConfigurePlayerMessageStyle({ color: 'red' });
    }

    const printSuccesfulConfigurePlayerMessage = (msg: string) => {
        setConfigurePlayerMessage(msg);
        setConfigurePlayerMessageStyle({ color: 'green' });
    }

    const generatePlayerId = (playerName: string) => {
        const playerId = playerName.replace(/\s/g, '').toLowerCase()
            + Math.floor(Math.random() * 900000000 + 100000000);

        return playerId;
    }

    const handleAddPlayerFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        configurePlayer();
    }

    const isValidEmail = (email: string) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    }

    const configurePlayer = async () => {
        // Make sure to validate the name input before adding the player
        if (newPlayerName !== '') {
            setIsConfiguringPlayer(true);
            const trimmedNewPlayerName = newPlayerName.trim().replace(/\s+/g, ' ');
            // Check if email is a valid format
            if (newPlayerEmail !== '' && !isValidEmail(newPlayerEmail)) {
                printUnsuccesfulConfigurePlayerMessage('Please enter a valid email');
            }
            // If new player name is already on the team
            else if (Object.values(teamData.players).some(player => player.toLowerCase() === trimmedNewPlayerName.toLocaleLowerCase())) {
                printUnsuccesfulConfigurePlayerMessage('Player name already exists on team');
            } else {
                const newPlayerData = {
                    teamId: teamData.id,
                    playerId: generatePlayerId(trimmedNewPlayerName),
                    playerName: trimmedNewPlayerName,
                    email: newPlayerEmail,
                    cellNumber: newPlayerCell,
                    playerType: newPlayerSpare ? 'spare' : 'regular'
                }

                await axiosAuth.post(playerApiUrl + encodeURIComponent(newPlayerData.playerId), newPlayerData);

                // If successful, update team data
                const response = await axiosAuth.get(teamApiUrl);
                console.log('Team data: ', response.data);
                setTeamData(response.data);

                printSuccesfulConfigurePlayerMessage(`${trimmedNewPlayerName} added successfully`);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
                setNewPlayerName('');
                setNewPlayerEmail('');
                setNewPlayerCell('');
            }
        }
        else {
            setNewPlayerName('');
            setNewPlayerEmail('');
            setNewPlayerCell('');
            setConfigurePlayerMessage('');
        }
        setIsConfiguringPlayer(false);
    };


    return (
        <form style={playerFormStyle} onSubmit={handleAddPlayerFormSubmit}>
            <TextField
                inputRef={inputRef}
                id="configure-player-name"
                label="Player Name"
                value={newPlayerName}
                onChange={(event) => {
                    setNewPlayerName(event.target.value);
                }}
                helperText=' '
            />
            <TextField
                id="configure-player-email"
                label="Player Email"
                value={newPlayerEmail}
                onChange={(event) => {
                    setNewPlayerEmail(event.target.value);
                }}
                helperText=' '
            />
            <TextField
                id="configure-player-cell"
                label="Player Cell"
                type='tel'
                value={newPlayerCell}
                onChange={(event) => {
                    setNewPlayerCell(event.target.value);
                }}
                helperText=' '
            />
            <div style={spareContainerStyle}>
                <FormControlLabel 
                    sx={spareCheckboxStyle} 
                    labelPlacement="start" 
                    control={
                        <Checkbox 
                            checked={newPlayerSpare}
                            onChange={(event) => {setNewPlayerSpare(event.target.checked)}}
                        />
                    }
                    label="Spare" 
                />
                <InfoModal sx={spareInfoStyle} infoHTML={infoModalHTML} />
            </div>
            <Button sx={{margin: '20px'}} variant="contained" type='submit' disabled={isConfiguringPlayer}>
                Add Player
            </Button>
            <p className='add-player-message' style={configurePlayerMessageStyle}>{configurePlayerMessage}</p>
        </form>
    );
}

const spareContainerStyle: React.CSSProperties = {
    position: 'relative',
    justifyContent: 'center',
    width: '195px'
}

const spareInfoStyle: React.CSSProperties = {
    display: 'flex',
    position: 'absolute',
    right : '20px',
    top: '50%',
    transform: 'translateY(-50%)',
}

const spareCheckboxStyle: React.CSSProperties = {
    margin: '0'
}

const playerFormStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export default ConfigurePlayer;
