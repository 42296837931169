import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ManagerLogin from '../Components/ManagerLogin';
import TeamSignUp from '../Components/TeamSignUp';
import GameLoginLogo from '../assets/gl_logo.png';
import axiosAuth from '../utils/axiosAuth';

import config from '../config.json';

const GameLoginHome = () => {
  const [teamCode, setTeamCode] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState(' ');
  const [playerLogIn, setPlayerLogIn] = useState('true');
  const [loginModeText, setLoginModeText] = useState('Manager Login')
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(true);
  const navigate = useNavigate(); // Create a history object
  const environment = process.env.REACT_APP_ENV;
  const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
  const teamApiUrl = baseApiUrl + 'teams/';
  const urlParams = useParams();

  useEffect(() => {
    // Check if the team code is set in the url params to redirect to email confirmation
    if (urlParams.teamId) {
      setSignUp(true);
      setTeamCode(urlParams.teamId);
      setUserConfirmed(false);
    }
  }, [urlParams]);

  const handleButtonClick = async () => {
    if (!teamCode) {
      setLoginErrorMessage('Team code required');
      return;
    }
    setIsLoggingIn(true);
    try {
      // Make the API request
      const response = await axiosAuth.get(teamApiUrl + teamCode.toLowerCase());

      console.log(response.data);

      // Navigate to the new screen when the API request is successful
      navigate(
        `/${teamCode.toLowerCase()}`
      );
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response.status === 404) {
        setLoginErrorMessage('Invalid team code');
      }
      else {
        setLoginErrorMessage(`Login failed, try again later.`);
      }
    }
    setIsLoggingIn(false);
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is 'Enter'
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const handleModeSwitch = () => {
    setSignUp(false);
    if (playerLogIn) {
      setPlayerLogIn(false);
      setLoginModeText("Player Login");
    }
    else {
      setPlayerLogIn(true);
      setLoginModeText("Manager Login");
    }    
  }

  const handleSignUpButton = () => {
    setSignUp(true); 
    setPlayerLogIn(false);
    setLoginModeText("Player Login");
  }

  return (
    <div className="home-page">
        <h1 className='homepage-title'>GameLogin</h1>
        <img src={GameLoginLogo} alt="" className='gl-logo' />
        <div className="home-input-container">
        {signUp ? (
          <TeamSignUp
            userConfirmed={userConfirmed}
            teamId={teamCode}
          />
        ) : (
          <div>
            {playerLogIn ? (
            <div className="team-input-container">
              <TextField
                autoFocus
                error={loginErrorMessage !== ' '}
                id="team-code"
                label="Team Code"
                onChange={(event) => {
                  setTeamCode(event.target.value);
                }}
                onKeyDown={handleKeyPress}
                helperText={loginErrorMessage}
              />
              <div className="team-go-button">
                <Button variant="contained" onClick={handleButtonClick} disabled={isLoggingIn}>Go</Button>
              </div>
            </div>
            ) : (
              <ManagerLogin />
            )}
          </div>
        )
        }
          <div style={loginModeButtonContainerStyle}>
            {!signUp &&
            <Button style={loginModeButtonStyle} onClick={handleSignUpButton}>Sign Up Team</Button>
            }
            <Button style={loginModeButtonStyle} onClick={handleModeSwitch}>{loginModeText}</Button>
          </div>
        </div>
    </div>
  );
};

const loginModeButtonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
  justifyContent: 'center',
  alignItems: 'center',
}

const loginModeButtonStyle = {
  width: '200px'
}



export default GameLoginHome;