import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CloseIcon from '@mui/icons-material/Close';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EmailIcon from '@mui/icons-material/Email';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { body, subject } from '../Templates/EmailGameReminder';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomButton = styled(IconButton)({
    '&:hover': {
      backgroundColor: 'lightgrey',
      borderRadius: '50%',
    },
});

const CreateGame = (props) => {
    const teamData = props.teamData;
    const setTeamData = props.setTeamData;
    const setShowEditGameModal = props.setShowEditGameModal;
    const [editGameId, setEditGameId] = useState(props.editGameId);
    const [editGameData, setEditGameData] = useState(null);
    const [loadingGameData, setLoadingGameData] = useState(true);
    const [loadingNotifData, setloadingNotifData] = useState(true);
    const [gameDateTime, setGameDateTime] = useState(null);
    const [gameLocation, setGameLocation] = useState('');
    const [createGameMessage, setCreateGameMessage] = useState('');
    const [createGameMessageStyle, setCreateGameMessageStyle] = useState({color: 'red'});
    const [notifNumIntervals, setNotifNumIntervals] = useState("48");
    const [notifIntervalType, setNotifIntervalType] = useState("hours");
    const [notificationEnabled, setNotificationEnabled] = useState(true);
    const [notifIntervalError, setNotifIntervalError] = useState(false);
    const [notifIntervalTypeError, setNotifIntervalTypeError] = useState(false);
    const [gameOpponent, setGameOpponent] = useState('');
    const [oldNotifNumIntervals, setOldNotifNumIntervals] = useState("");
    const [oldNotifIntervalType, setOldNotifIntervalType] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [deletingGame, setDeletingGame] = useState(false);
    const [confirmDeleteGame, setConfirmDeleteGame] = useState(false);

    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`
    const gameApiUrl = teamApiUrl + '/games/'
    const emailNotifApiUrl = baseApiUrl + 'emailNotifs/';

    
    useEffect(() => {
        if (editGameId) {
            axiosAuth.get(gameApiUrl + encodeURIComponent(editGameId))
                .then(response => {
                    setEditGameData(response.data);
                    if (response.data.emailNotifs && response.data.emailNotifs.length > 0) {
                        axiosAuth.get(emailNotifApiUrl + response.data.emailNotifs[0]).then((response) => {
                            formatEmailNotifData(response.data);
                            console.log('Game notif response: ', response.data);
                        })
                        .catch(error => {
                            console.error('Error fetching emailNotif data:', error);
                            throw error;
                        });
                    }
                    else {
                        setNotificationEnabled(false);
                        setloadingNotifData(false);
                    }
                    setGameDateTime(dayjs(response.data.dateTimeLocKey.split('_')[0]));
                    setGameLocation(response.data.dateTimeLocKey.split('_')[1]);
                    setGameOpponent(response.data.opponent);
                    console.log('EditGame response: ', response.data);
                    setLoadingGameData(false);
                })
                .catch(error => {
                    console.error('Error fetching game data:', error);
                });
        }
    }, [editGameId, gameApiUrl, emailNotifApiUrl]);
    

    const formatEmailNotifData = (emailNotifData) => {
        if (!emailNotifData) {
            setNotificationEnabled(false);
            return;
        }
        const notifDate = new Date(emailNotifData.notifTime);
        const gameDate = new Date(emailNotifData.gameId.split('_')[0]);

        const differenceInMilliseconds = (gameDate - notifDate);
        const differenceInHours = differenceInMilliseconds / (1000 * 3600);
        //const differenceInDays = differenceInHours / 24;

        /*if (differenceInDays % 1 === 0) {
            setOldNotifIntervalType('days');
            setNotifIntervalType('days');
            setOldNotifNumIntervals(differenceInDays);
            setNotifNumIntervals(differenceInDays);
        } else {*/
            setOldNotifIntervalType('hours');
            setNotifIntervalType('hours');
            setOldNotifNumIntervals(differenceInHours);
            setNotifNumIntervals(differenceInHours);
        //}
        setloadingNotifData(false);
    }

    const printUnsuccesfulCreateGameMessage = (msg) => {
        setCreateGameMessage(msg);
        setCreateGameMessageStyle({ color: 'red' });
    }

    const printSuccesfulCreateGameMessage = (msg) => {
        setCreateGameMessage(msg);
        setCreateGameMessageStyle({ color: 'green' });
    }

    const convertLocalTimeToUTC = (localTimeString) => {
        // Create a new Date object from the local time string
        const localDate = new Date(localTimeString);

        return localDate.toISOString();
    }

    const getGameNotifcationTime = (localTimeString, intervals, intervalType) => {
        // Create a new Date object from the local time string
        const localDate = new Date(localTimeString);

        // Convert intervals to milliseconds
        const millisecondsPerHour = 3600000; // 60 minutes * 60 seconds * 1000 milliseconds
        const millisecondsPerDay = millisecondsPerHour * 24;
        let intervalInMilliseconds;

        if (intervalType === "hours") {
            intervalInMilliseconds = intervals * millisecondsPerHour;
        } else if (intervalType === "days") {
            intervalInMilliseconds = intervals * millisecondsPerDay;
        }

        // Subtract the intervals from the date
        localDate.setTime(localDate.getTime() - intervalInMilliseconds);

        // Return the adjusted date as an ISO string (in UTC)
        return localDate;
    }

    const formatLocaleDateTime = (localDateTime) => {
        const utcDateObj = new Date(localDateTime);

        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: teamData.timeZone
          };

        return utcDateObj.toLocaleString('en-US', options);
    }

    const formatString = (template, args) => {
        return template.replace(/{(\w+)}/g, function(match, key) { 
            return args[key]
                ? args[key]
                : match;
        });
    }

    const handleCreateGameButton = async () => {
        setIsSaving(true);

        await createGame();

        setIsSaving(false);
    }

    const createGame = async () => {
        setNotifIntervalError(false);
        setNotifIntervalTypeError(false);
        setCreateGameMessage('');

        // First check the required fields are filled
        if (!gameDateTime || gameDateTime === '') {
            printUnsuccesfulCreateGameMessage('Please enter a date and time');
            return;
        }
        else if (!gameLocation || gameLocation === '') {
            printUnsuccesfulCreateGameMessage('Please enter a game location');
            return;
        }

        if (!gameNotificationValid()) {
            return;
        }

        const gameRequestBody = {};
        if (notificationEnabled) {
            gameRequestBody.emailNotif = createGameNotification();
        }

        console.log(gameDateTime);
        gameRequestBody.gameData = {
            teamId: teamData.id,
            dateTimeLocKey: convertLocalTimeToUTC(gameDateTime) + '_' + gameLocation,
            opponent: gameOpponent,
            loggedIn: {},
            emailNotifs: [],
            version: 0
        };
        if (teamData.autoLoginPlayers) {
            teamData.autoLoginPlayers.forEach((playerId) => {
                gameRequestBody.gameData.loggedIn[playerId] = {
                    group: "Home"
                }
            })
        }
        if (teamData.games.includes(gameRequestBody.gameData.dateTimeLocKey)) {
            printUnsuccesfulCreateGameMessage('A game with this date, time and location already exists for this team');
        }
        else {
            console.log('New Game to Create:', gameRequestBody.gameData);
            
            try {
                await axiosAuth.post(gameApiUrl + encodeURIComponent(gameRequestBody.gameData.dateTimeLocKey), gameRequestBody)

                setGameDateTime(null);
                setGameLocation('');
                setGameOpponent('');
                printSuccesfulCreateGameMessage('Game Created!');
            } 
            catch (error) {
                console.error('Create game failed with:', error);
                printUnsuccesfulCreateGameMessage('Create game failed');
            }
            finally {
                const response = await axiosAuth.get(teamApiUrl);
                setTeamData(response.data);
            }
        }
    }

    const handleSaveGameButton = async () => {
        setIsSaving(true);

        await saveGame();

        setIsSaving(false);
    }

    const saveGame = async () => {
        setNotifIntervalError(false);
        setNotifIntervalTypeError(false);
        setCreateGameMessage('');

        // First check the required fields are filled
        if (!gameDateTime || gameDateTime === '') {
            printUnsuccesfulCreateGameMessage('Please enter a date and time');
            return;
        }
        else if (!gameLocation || gameLocation === '') {
            printUnsuccesfulCreateGameMessage('Please enter a game location');
            return;
        }

        if (!gameNotificationValid()) {
            return;
        }

        if (gameDateTime === editGameData.dateTimeLocKey.split('_')[0]
                && gameLocation === editGameData.dateTimeLocKey.split('_')[1]
                && gameOpponent === editGameData.opponent
                && notifIntervalType === oldNotifIntervalType
                && notifNumIntervals === oldNotifNumIntervals) {
            printUnsuccesfulCreateGameMessage('No changes were made to game info');
            return;
        }

        const newDateTimeLocKey = convertLocalTimeToUTC(gameDateTime) + '_' + gameLocation;

        if (newDateTimeLocKey !== editGameId && teamData.games.includes(newDateTimeLocKey)) {
            printUnsuccesfulCreateGameMessage('A game with this date, time and location already exists for this team');
        }

        // Create new game data object to update
        const gameRequestBody = {};
        gameRequestBody.gameData = {
            ...editGameData,
            dateTimeLocKey: newDateTimeLocKey,
            opponent: gameOpponent,
            version: editGameData.version + 1,
            // Always delete the game notifs if any changes were made
            emailNotifs: []
        };
        if (notificationEnabled) {
            gameRequestBody.emailNotif = createGameNotification();
        }
        console.log('Editing Game:', gameRequestBody.gameData);
        try {
            await axiosAuth.put(gameApiUrl + encodeURIComponent(editGameId), gameRequestBody);

            printSuccesfulCreateGameMessage('Game Saved!');
        } 
        catch (error) {
            console.error('Edit game failed with:', error);
            printUnsuccesfulCreateGameMessage('Edit game failed');
        }
        finally {
            const response = await axiosAuth.get(teamApiUrl);
            setTeamData(response.data);
            editGameData.version++;
            setEditGameId(newDateTimeLocKey);
        }
    }

    const gameNotificationValid = () => {
        if (!notificationEnabled) {
            return true;
        }

        if (Number(notifNumIntervals) <= 0 || Number(notifNumIntervals) >= 999) {
            printUnsuccesfulCreateGameMessage('Notification intervals must be between 1 and 999');
            setNotifIntervalError(true);
            return false;
        }

        // Check that date is in the future
        if (getGameNotifcationTime(gameDateTime, notifNumIntervals, notifIntervalType) < (new Date())) {
            printUnsuccesfulCreateGameMessage('Notification date must be set for the future');
            setNotifIntervalError(true);
            setNotifIntervalTypeError(true);
            return false;
        }
        return true;
    }

    const createGameNotification = () => {
        const formattedLocalDateTime = formatLocaleDateTime(gameDateTime);

        const newGameNotification = {
            gameId: convertLocalTimeToUTC(gameDateTime) + '_' + gameLocation,
            teamId: teamData.id,
            notifTime: getGameNotifcationTime(gameDateTime, notifNumIntervals, notifIntervalType).toISOString(),
            emailSubject: formatString(
                subject, 
                {teamName: teamData.name, gameDateTime: formattedLocalDateTime}
            ),
            emailBody: formatString(
                body, 
                {gameDateTime: formattedLocalDateTime, teamId: teamData.id, gameLocation: gameLocation}
            )
        };
        console.log('Game notification to create', newGameNotification);

        return newGameNotification;
    };

    const handleRemoveNotification = () => {
        setNotificationEnabled(false);
        setNotifIntervalType('');
        setNotifNumIntervals('');
    };

    const handleAddNotification = () => {
        setNotificationEnabled(true);
        setNotifIntervalType('hours');
        setNotifNumIntervals('48');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (editGameId) {
            handleSaveGameButton();
        }
        else {
            handleCreateGameButton();
        }
    }

    const deleteGame = async () => {
        try {
            const response = await axiosAuth.delete(gameApiUrl + encodeURIComponent(editGameId));
            console.log('delete game response: ' + response);
        } catch (err) {
            console.error(err);
        } finally {
            const response = await axiosAuth.get(teamApiUrl);
            setTeamData(response.data);
        }
    }

    const handleDeleteGame = async () => {
        setCreateGameMessage('');
        setDeletingGame(true);
        await deleteGame();
        setDeletingGame(false);
        setShowEditGameModal(false);
    }

    return (
        <div>
        {!editGameId || (!loadingGameData && !loadingNotifData) ? (
        <form className='create-game-form' onSubmit={handleSubmit}>
            {editGameId ? (
                <h3 id='create-game-header'>Edit Game</h3>
            ): (
                <h3 id='create-game-header'>Create Game</h3>
            )}
            <div className='create-game-input-container'>
                <div className='create-game-field'>
                    <EditCalendarIcon className='create-game-icon'/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker 
                            label="Date and time"
                            value={gameDateTime}
                            onChange={(newValue) => setGameDateTime(newValue)}
                            sx={{width: '100%'}}
                            timezone={teamData.timeZone}
                        />
                    </LocalizationProvider>
                </div>
                <div className='create-game-field'>
                    <AddLocationAltIcon className='create-game-icon'/>
                    <TextField
                        id="create-game-location-input"
                        label="Location"
                        value={gameLocation}
                        onChange={(event) => {
                            setGameLocation(event.target.value);
                        }}
                        sx={{width: '100%'}}
                    />
                </div>
                <div className='create-game-field'>
                    <SafetyDividerIcon className='create-game-icon'/>
                    <TextField
                        id="create-game-oponent-input"
                        label="Opponent (optional)"
                        value={gameOpponent}
                        onChange={(event) => {
                            setGameOpponent(event.target.value);
                        }}
                        sx={{width: '100%'}}
                    />
                </div>
                <div className='create-game-field'>
                    <EmailIcon className='create-game-icon'/>
                    {notificationEnabled ? (
                    <div className='notif-picker'>
                        <Box display="flex" alignItems="center">
                            <TextField
                                error={notifIntervalError}
                                style={{ width: '70px', height: '40px'}}
                                type="number"
                                variant="outlined"
                                size="small"
                                value={notifNumIntervals}
                                onChange={(event) => setNotifNumIntervals(event.target.value)}
                                InputProps={{
                                    inputProps: {
                                        min: 1,    // Set your desired minimum value
                                        step: 1   // Set the desired interval
                                    }
                                }}
                            />
                        </Box>
                        <Select
                            error={notifIntervalTypeError}
                            labelId="time-interval-label"
                            id="time-interval"
                            value={notifIntervalType}
                            onChange={(event) => setNotifIntervalType(event.target.value)}
                            label=""
                            style={{ width: '100px', height: '40px'}}
                        >
                            <MenuItem value={'hours'}>hours</MenuItem>
                            <MenuItem value={'days'}>days</MenuItem>
                        </Select>
                        <CustomButton onClick={handleRemoveNotification}>
                            <CloseIcon />
                        </CustomButton>
                    </div>
                    ) : (
                    <div id='create-game-notif-button'>
                        <Button variant='text' onClick={handleAddNotification} style={{height: '40px'}}>
                            Add Reminder
                        </Button>
                    </div>
                    )}
                </div>
            </div>
            {editGameId ? (
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    {confirmDeleteGame ? (
                    <div style={deleteGameConfirmationStyle}>
                        <p style={{marginTop: '0'}}>Are you sure you want to delete this game?</p>
                        <div style={editGameButtonContainerStyle}>
                            <Button style={editGameButtonStyle} onClick={() => setConfirmDeleteGame(!confirmDeleteGame)} variant='contained'>Cancel</Button>
                            <Button style={{...editGameButtonStyle, backgroundColor: 'red'}} variant='contained' onClick={handleDeleteGame} disabled={deletingGame}>Delete</Button>
                        </div>
                    </div>
                    ) : (
                    <div style={editGameButtonContainerStyle}>
                        <Button style={{...editGameButtonStyle, backgroundColor: 'red'}} variant='contained' onClick={() => setConfirmDeleteGame(!confirmDeleteGame)} disabled={isSaving}>
                            Delete
                        </Button>
                        <Button style={editGameButtonStyle} variant='contained' type='submit' disabled={isSaving}>
                            Save
                        </Button>
                    </div>
                    )}
                </div>
            ) : (
                <Button variant='contained' type='submit' onClick={handleCreateGameButton} disabled={isSaving}>
                    Create Game
                </Button>
            )}
            <p className='create-game-message' style={createGameMessageStyle}>{createGameMessage}</p>
        </form>
        ) : (
            <p>Loading Game Data</p>
        )}
        </div>
    );


}

const editGameButtonContainerStyle = {
    display: 'flex',
    gap: '60px',
    justifyContent: 'center',
}

const editGameButtonStyle = {
    width: '80px'
}

const deleteGameConfirmationStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    justifySelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}


export default CreateGame;