import { AuthenticationResultType, InitiateAuthCommand, InitiateAuthCommandInput } from "@aws-sdk/client-cognito-identity-provider";
import { client, poolClientId } from '../Modules/cognito-config';


const authenticateUser = async (userId: string, password: string) => {
    const cognitoAuthInput = {
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
            USERNAME: userId,
            PASSWORD: password
        },
        ClientId: poolClientId,
    } as InitiateAuthCommandInput;

    const cognitoAuthResponse = await client.send(new InitiateAuthCommand(cognitoAuthInput));
    console.log(cognitoAuthResponse);
    const authenticationResult = cognitoAuthResponse.AuthenticationResult;
    
    if (!authenticationResult) {
        throw new Error('Failed to authorize user');
    }
    // Store userId related to the returned tokens
    localStorage.setItem('teamId', userId);
    storeTokens(authenticationResult);
}

const refreshCognitoTokens = async () => {
    console.log('refreshing tokens...')
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    const params = {
        ClientId: poolClientId,
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
            REFRESH_TOKEN: refreshToken,
        },
    } as InitiateAuthCommandInput;

    try {
        const response = await client.send(new InitiateAuthCommand(params));
        const authenticationResult = response.AuthenticationResult;
        console.log(authenticationResult);

        if (!authenticationResult) {
            throw new Error('Failed to refresh token');
        }

        // Update localStorage with new tokens
        storeTokens(authenticationResult);

        return {
            idToken: authenticationResult.IdToken,
            accessToken: authenticationResult.AccessToken,
        };
    } catch (error) {
        console.error('Error refreshing token: ', error);
        throw error;
    }
}

function storeTokens(authenticationResult: AuthenticationResultType) {
    if (!authenticationResult) {
        throw new Error('Failed to refresh token');
    }

    // Update localStorage with new tokens
    if (!authenticationResult.IdToken) {
        throw new Error('IdToken not present for refresh')
    }
    else {
        localStorage.setItem('idToken', authenticationResult.IdToken);
    }

    if (!authenticationResult.AccessToken) {
        throw new Error('AccessToken not present for refresh')
    }
    else {
        localStorage.setItem('accessToken', authenticationResult.AccessToken);
    }

    if (authenticationResult.RefreshToken) {
        localStorage.setItem('refreshToken', authenticationResult.RefreshToken);
    }
    const tokenExpiration = authenticationResult.ExpiresIn ? authenticationResult.ExpiresIn : 3600;
    localStorage.setItem('refreshTime', calculateRefreshTime(tokenExpiration).toString());
}

function calculateRefreshTime(refreshExpiration: number) {
    const refreshThreshold = 300; // 5 minutes early for smooth expirience
    const now = Math.floor(Date.now() / 1000);
    const expirationTime = now + refreshExpiration;
    const refreshTime = expirationTime - refreshThreshold;

    return refreshTime;
}

export { authenticateUser, refreshCognitoTokens };

