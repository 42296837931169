import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GameLineup from '../Components/GameLineup';
import ManagerLogin from '../Components/ManagerLogin';
import TeamNotices from '../Components/TeamNotices';
import UpcomingGames from '../Components/UpcomingGames';
import LoadingGif from '../assets/loading.gif';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';



const TeamPage = () => {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamId}`
    const teamLogoApiUrl = teamApiUrl + '/logo';
    const [teamData, setTeamData] = useState(null);
    const [teamLogo, setTeamLogo] = useState(null);
    const [loadingTeamLogo, setLoadingTeamLogo] = useState(true);
    const [managerLoginOpen, setManagerLoginOpen] = useState(false);

    // useEffect hook to get the team data on every refresh of the page in order to keep the team information up to date
    useEffect(() => {
        console.log('Updating team data...');
        axiosAuth.get(teamApiUrl)
            .then(response => {
                setTeamData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                navigate('/');
            });
        // Set current logo to teamLogo state variable
        axiosAuth.get(teamLogoApiUrl)
            .then(response => {
                const signedUrl = response.data.signedUrl;
                console.log(signedUrl);
                axios.get(signedUrl, { responseType: 'blob' })
                    .then(response => {
                        const teamLogo = new File([response.data], 'downloaded_logo.png', { type: response.headers['content-type'] });
                        console.log('Downloaded logo as File:', teamLogo);
                        setTeamLogo(teamLogo);
                        setLoadingTeamLogo(false);
                    })
                    .catch(error => {
                        console.log('Error downloading file:', error);
                    });
            }).catch(error => {
                if (error.response.status && error.response.status === 404) {
                    console.log('No team logo set');
                    setLoadingTeamLogo(false);
                } else {
                    console.log('Error getting signed URL:', error);
                }
            });
    }, [teamApiUrl, navigate, teamLogoApiUrl]);


    const handleOpenManagerLogin = () => {
        setManagerLoginOpen(true);
    }

    const handleCloseManagerLogin = () => {
        setManagerLoginOpen(false);
    }

    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 270,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
      };

    return (
        <div>
        {teamData && teamData.notices && !loadingTeamLogo ? (
        <div className="container">
            <h2>{teamData.name}</h2>
            {teamLogo && (
            <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(teamLogo)}
            />
            )}
            <br />
            <TeamNotices
                teamData={teamData}
                setTeamData={setTeamData}
            />
            <br />
            <GameLineup 
                teamData={teamData}
                setTeamData={setTeamData}
                allowLogout={'allowLogout' in teamData ? teamData.allowLogout : false}
                showInfoButton={'allowLogout' in teamData ? teamData.allowLogout : false}
            />
            <div className='upcoming-games-container'>
                <UpcomingGames
                    teamData={teamData}
                    setTeamData={setTeamData}
                    allowEdit={false}
                />
            </div>
            <div id='tp-manager-login'>
                <Button variant='outlined' onClick={handleOpenManagerLogin}>Manager Login</Button>
                <br />
                <br />
                <br />
                <Modal
                    open={managerLoginOpen}
                    onClose={handleCloseManagerLogin}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-box" sx={boxStyle}>
                        { managerLoginOpen ? (
                        <ManagerLogin teamId={teamId}/>
                        ) : null
                        } 
                    </Box>
                </Modal>
            </div>
        </div>
        ) : (
        <div style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={LoadingGif} alt="Loading..." style={{width: '100px'}}/>
        </div>
        )}
        </div>
    );
};

export default TeamPage;