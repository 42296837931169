import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';

const LoginInput = ({ playerOptions, teamData, nextGameData, setTeamData, updateTeamData }) => {
    const [ loginValue, setLoginValue ] = useState(null);
    const [ loginInputValue, setLoginInputValue ] = useState('');
    const [ loginMessage, setLoginMessage ] = useState('');
    const [ loginMessageError, setLoginMessageError ] = useState(false);
    const [ isLoggingIn, setIsLoggingIn ] = useState(false);
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`
    const playerApiUrl = teamApiUrl + '/players/';
    const gameApiUrl = teamApiUrl + `/games/${encodeURIComponent(nextGameData.dateTimeLocKey)}`;
    const loginPlayerApiUrl = gameApiUrl + '/login/';

    const printUnsuccesfulLoginMessage = (msg) => {
        setLoginMessage(msg);
        setLoginMessageError(true);
    }

    const printSuccessfulLoginMessage = (msg) => {
        setLoginMessage(msg);
        setLoginMessageError(false);
    }

    const handleKeyPress = (event) => {
        // Check if the pressed key is 'Enter'
        if (!loginValue) {
            return;
        }
        if (event.key === 'Enter') {
          handleLoginButtonClick();
        }
    };

    const loginPlayerOnServer = async (playerId) => {
        // Make a POST request to login player on server
        const postResponse = await axiosAuth.post(loginPlayerApiUrl + encodeURIComponent(playerId));

        return postResponse.data;
    };

    // Function to handle login button click and validate input
    const handleLogin = async () => {
        if (loginInputValue.trim() === '') {
            setLoginMessageError(false);
            setLoginMessage('');
            return
        }

        //Check if input player exists, to allow lower case player input
        const truePlayerName = Object.values(teamData.players).find(
            (player) => player.toLowerCase() === loginInputValue.toLowerCase()
        );

        // Get playerId for player
        const playerId = Object.keys(teamData.players).find(
            key => teamData.players[key] === truePlayerName
        );

        // Check if player is a spare
        if (await isPlayerSpare(playerId)) {
            // Check if within the designated window
            const gameTime = new Date(nextGameData.dateTimeLocKey.split('_')[0]);
            const currentTime = new Date();
            const timeDifference = gameTime - currentTime;
            const maxDifference = 60 * 60 * 60 * 1000;
            if (timeDifference > maxDifference) {
                const loginTime = new Date(gameTime.getTime() - maxDifference);
                console.log('Player is not within the spare window');
                const options = {
                    weekday: 'long',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                };
                printUnsuccesfulLoginMessage(`${teamData.players[playerId]} is a spare, log in not allowed until ${loginTime.toLocaleString('en-US', options)}`);
                return;
            }
        }

        const retries = 3;
        let currentGameData = nextGameData;
        for (let i = 0; i < retries; i++) {
            try {
                // If previous attempt to push failed, try to update local data
                if (i > 0) {
                    const gameResponse = await axiosAuth.get(gameApiUrl + currentGameData.dateTimeLocKey);
                    console.log('GET game response:', gameResponse);
                    // If loggedIn is in old array format, convert
                    if (Array.isArray(gameResponse.data.loggedIn)) {
                        const newLoggedIn = {}
                        gameResponse.data.loggedIn.forEach((playerId) => {
                            newLoggedIn[playerId] = { group: "Home" }
                        })
                        gameResponse.data.loggedIn = newLoggedIn;
                    }
                    currentGameData = gameResponse.data;
                }

                // Validate input
                if (playerId in currentGameData.loggedIn) {
                    printUnsuccesfulLoginMessage(loginInputValue + ' is already logged in');
                    updateTeamData();
                    break;
                }
                else if (Object.keys(currentGameData.loggedIn).length >= teamData.maxPlayers) {
                    printUnsuccesfulLoginMessage('Max players has already been reached');
                    updateTeamData();
                    break;
                }
                else if (!(playerId in teamData.players)) {
                    printUnsuccesfulLoginMessage(loginInputValue + ' does not belong to this team, contact your team admin to be added');
                    updateTeamData();
                    break;
                }
                else {
                    // Attempt to update team table value
                    const responseData = await loginPlayerOnServer(playerId); ////////
                    if (responseData.gameData) {
                        updateTeamData();
                    }
                    else {
                        // Trigger update of game data by setting teamData
                        setTeamData({ ...teamData })
                    }

                    console.log('Game data update successful');
                    setLoginInputValue('');
                    setLoginValue(null);
    
                    // Log and display a success message
                    console.log(`${truePlayerName} successfully logged in`);
                    printSuccessfulLoginMessage(`${truePlayerName} logged in!`);
                    break;
                }
            }
            catch (error) {
                console.error(`Attempt ${i+1} - Error updating teamData:`, error);
                if (i === retries-1) {
                    printUnsuccesfulLoginMessage('Failed to login player');
                }
            }
        }
    }

    const handleLoginButtonClick = async () => {
        try {
            setIsLoggingIn(true);
            await handleLogin();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoggingIn(false);
        }
    }
    
    const isPlayerSpare = async (playerId) => {
        try {
            const response = await axiosAuth.get(playerApiUrl + encodeURIComponent(playerId));
            return response.data.playerType === 'spare' ? true : false;
            
        } catch (error) {
            printUnsuccesfulLoginMessage('Failed to login player');
            throw error;
        }
    }

    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) =>
          option.toLowerCase().startsWith(inputValue.toLowerCase())
        );
    };

    return (
        <div className='player-login-container'>
            <div className='player-input-container'>
                <Autocomplete
                    disablePortal
                    id="login-input"
                    filterOptions={filterOptions}
                    options={playerOptions}
                    value={loginValue}
                    onChange={(event, newValue) => {
                        setLoginValue(newValue);
                    }}
                    freeSolo
                    inputValue={loginInputValue}
                    onInputChange={(event, newInputValue) => {setLoginInputValue(newInputValue);console.log(newInputValue);}}
                    sx={{
                        width: 240
                    }}
                    onKeyDown={handleKeyPress}
                    renderInput={(params) => <TextField {...params} error={loginMessageError} label="Player Name" helperText={loginMessage} FormHelperTextProps={{sx: {color: 'green'}}}/>}
                />
            </div>
            <div className='login-button'>
                <Button variant="contained" onClick={handleLoginButtonClick} disabled={isLoggingIn}>
                    Login
                </Button>
            </div>
        </div>
      );
}

export default LoginInput;