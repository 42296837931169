import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import config from '../config.json';
import axiosAuth from '../utils/axiosAuth';


const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 280,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const DeleteNoticeModal = ({showDeleteModal, setShowDeleteModal, noticeIndex, teamData, setTeamData}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const environment = process.env.REACT_APP_ENV;
    const baseApiUrl = environment ? config[environment].apiUrl : config.dev.apiUrl;
    const teamApiUrl = baseApiUrl + `teams/${teamData.id}`


    const handleDeleteButton = async () => {
        const newTeamData = { ...teamData };
        newTeamData.notices = [ ...teamData.notices ];
        newTeamData.notices.splice(noticeIndex, 1);
        newTeamData.version++;
        console.log(newTeamData);
        
        setIsDeleting(true);
        await updateTeamNoticesOnServer(newTeamData);
        setShowDeleteModal(false);
        setIsDeleting(false);
    }

    const updateTeamNoticesOnServer = async (newTeamData) => {
        try{
            await axiosAuth.put(teamApiUrl, newTeamData);

            setTeamData(newTeamData);
        } catch (error) {
            if (!error.response) {
                console.log(error);
            }
            else {
                console.error(error.response);
            }
        }
    }

    return (
        <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
        >
            <Box sx={boxStyle}>            
                <div className="delete-notice-modal-content">
                    <p>Are you sure you want to delete this team notice?</p>
                    <div className='notice-item'>
                        <p>{teamData.notices[noticeIndex]}</p>
                    </div>
                    <div className="delete-notice-modal-buttons">
                        <Button variant="contained" onClick={() => setShowDeleteModal(false)}>
                            No
                        </Button>
                        <Button variant="contained" onClick={handleDeleteButton} disabled={isDeleting}>
                            Yes
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default DeleteNoticeModal;